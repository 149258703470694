import styles from './Index.module.css';
import React, { useState, useEffect, useCallback } from "react";
import ModalWindow from '../ModalWindow/ModalWindow';
import PageHeader from '../PageHeader/PageHeader';
import SecondWindow from '../SecondWindow/SecondWindow';
import { useLocation } from 'react-router-dom';
import logo from "../../assets/logoLift.png"
import avatar from "../../assets/avatar.png"
import waves from "../../assets/waves.png"
import waves2 from "../../assets/waves2.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faPhoneVolume, faTrashCan, faWifi } from '@fortawesome/free-solid-svg-icons'
import cardBCK from "../../assets/card-BCK.jpg"
import axios from 'axios';
import url from "../../constants/url";
import MainCoverComponent from "../MainCoverComponent/MainCoverComponent";
import { useTranslation } from 'react-i18next';
function Cards() {
    const location = useLocation();
    const { t } = useTranslation();
    const [modalType, setModalType] = useState("");
    const [modal, setModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [degree, setDegree] = useState(-90);
    const [degree2, setDegree2] = useState(0);
    const [display, setDisplay] = useState("none");
    const [display2, setDisplay2] = useState("flex");
    const [cards, setCards] = useState([]);
    const [activeCard, setActiveCard] = useState(0)

    function cardStatusChangeHandler(status) {

        if (status === 1) {
            setDegree(90)
            setTimeout(() => {
                setDisplay("none")
                setDisplay2("flex")
                setTimeout(() => { setDegree2(0); setDegree(-90); }, 50)
            }
                , 600)
        }
        else if (status === 0) {
            setDegree2(90)
            setTimeout(() => {
                setDisplay2("none")
                setDisplay("flex")
                setTimeout(() => { setDegree(0); setDegree2(-90); }, 50)
            }
                , 600)
        }
    }

    const reloadList = useCallback(() => {
        axios({
            method: 'get',
            url: url + "/get_cards_by_user",
            headers: {
                'authKey': localStorage.getItem("auth_key"),
                'user': location.state.user
            }
        })
            .then(function (response) {
                setCards([...response.data]);
                setActiveCard(response.data[0].card);
            })
            .catch(function (error) {
                console.error("Failed to load cards:", error);
            });
    }, [location.state.user]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);


    return (
        <div className={styles.main}>

            {modal ? <ModalWindow
                modalType={modalType}
                modalData={{ ...modalData }}
                setModal={setModal}
                reloadList={() => reloadList()}
            /> : null}

            <MainCoverComponent>
                <PageHeader>{t('Cards')}  </PageHeader>

                <SecondWindow>

                    {console.log(location.state)}
                    <div className={styles.mainContainer}>


                        <div className={styles.mainUserInfoContainer}>

                            <div className={styles.mainUserInfo}>
                                <div className={styles.headerBCG}>
                                    <div className={styles.avatarContainer}>
                                        <img src={avatar} alt="Avatar" className={styles.avatar} />
                                    </div>
                                </div>
                                {console.log("location.state")}
                                {console.log(location.state)}
                                <p className={styles.userHeader}>{location.state.name + " " + location.state.surname + " " + location.state.fatherName}</p>

                                <div className={styles.infoBox}>
                                    <p className={styles.infoDescription}>{t('Company')}: </p>
                                    <p className={styles.infoData}>{location.state.companyName}</p>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.infoDescription}>{t('Building')}: </p>
                                    <p className={styles.infoData}>{location.state.building_name}</p>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.infoDescription}>{t('Entrance')}: </p>
                                    <p className={styles.infoData}>{location.state.blok_name}</p>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.infoDescription}>{t('Floor')}: </p>
                                    <p className={styles.infoData}>{location.state.floor}</p>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.infoDescription}>{t('Apartment')}: </p>
                                    <p className={styles.infoData}>{location.state.apartment}</p>
                                </div>
                                <div className={styles.infoBox}>
                                    <p className={styles.infoDescription}>{t('Number')}: </p>
                                    <p className={styles.infoData}>{location.state.number}</p>
                                </div>
                            </div>
                        </div>
                        {console.log(cards)}
                        <div className={styles.userData}>

                            <div
                                onClick={() => cardStatusChangeHandler(1)}
                                className={styles.userInfo}
                                style={{
                                    'backgroundImage': `url(${cardBCK})`,
                                    'transform': `rotateY(${degree}deg)`,
                                    "display": `${display}`
                                }}
                            >
                                <FontAwesomeIcon icon={faWifi} className={styles.iconCard} />
                                <img src={logo} alt="Logo" className={styles.logo} />
                                <img src={waves} alt="Logo" className={styles.wave} />
                            </div>



                            <div
                                onClick={() => cardStatusChangeHandler(0)}
                                className={styles.userInfo2}
                                style={{
                                    'transform': `rotateY(${degree2}deg)`,
                                    "display": `${display2}`
                                }}
                            >
                                <img src={logo} alt="Logo" className={styles.logo2} />
                                <img src={waves2} alt="Logo" className={styles.waves2} />
                                <div className={styles.contact}>
                                    <FontAwesomeIcon icon={faPhoneVolume} className={styles.iconPhone} />
                                    <p>(012) 310-32-10 </p>
                                </div>
                                <div className={styles.rules}>
                                    <p> - Kart Balansının artırılması üsulları:</p>
                                    <p> - Ödəniş terminalları</p>
                                    <p> - Lift Card mobil tətbiqi</p>
                                    <p> - Bank mobil tətbiqləri</p>
                                    <p> - Bina kommunal məntəqələrində nəğd formada</p>
                                </div>
                                <p className={styles.hexCode}>{activeCard}</p>
                            </div>




                            <div className={styles.cardsTableContainer}>
                                <div
                                    onClick={() => {
                                        setModalType("addCard")
                                        setModalData(location.state)
                                        setModal(true)
                                    }}
                                    className={styles.addButton}>
                                    {t('Add')}
                                </div>
                                <div className={styles.firstRawContainer}>
                                    <p className={styles.tableHeader}>№</p>
                                    <p className={styles.tableHeader}>{t('Full Name')}</p>

                                </div>

                                <div className={styles.tableDataContainer}>
                                    {cards.map((item, index) => {
                                        return <div className={item.card === activeCard ? styles.tableRowCardActive : styles.tableRowCard} onClick={() => setActiveCard(item.card)}>
                                            <p className={styles.tableContent}>{index + 1}</p>
                                            <p className={styles.tableContent}>{item.fullname}</p>
                                            <div className={styles.buttonsContainer}>
                                                <FontAwesomeIcon onClick={() => {
                                                    setModalType("editCard")
                                                    setModalData(item)
                                                    setModal(true)
                                                }} icon={faPenToSquare} className={styles.iconEdit} />
                                                
                                                {
                                                    localStorage.getItem("type") === "0" || localStorage.getItem("type") === "1" ?
                                                    <FontAwesomeIcon onClick={() => {
                                                        setModalType("deleteCard")
                                                        setModalData(item)
                                                        setModal(true)
                                                    }} icon={faTrashCan} className={styles.iconDelete} />
                                                    : null
                                                }
                                                
                                            </div>
                                        </div>
                                    })
                                    }


                                </div>

                            </div>

                        </div>

                    </div>
                </SecondWindow>
            </MainCoverComponent>
        </div>
    );
}

export default Cards;