import styles from './Index.module.css';
import React, { useState, useEffect } from "react";
import ModalWindow from '../ModalWindow/ModalWindow';
import PageHeader from '../PageHeader/PageHeader';
import SecondWindow from '../SecondWindow/SecondWindow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import axios from 'axios';
import url from "../../constants/url";
import MainCoverComponent from "../MainCoverComponent/MainCoverComponent";
import SearchInput from "../SearchInput/SearchInput";
import { useTranslation } from 'react-i18next';
function ListCompaniesFiltered() {
    const [search, setSearch] = useState('');
    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalData, setModalData] = useState(null);
    const [list, setList] = useState([]);
    const { t } = useTranslation();

    function reloadList() {
        axios({
            method: 'get',
            url: url + "/get_companies",
            headers: {
                'authKey': localStorage.getItem("auth_key")
            }
        })
            .then(function (response) {
                console.log("success")
                console.log(response.data)
                setList([...response.data]);
            }).catch(function (error) {
                console.log("fail")
                console.log(error)
            });
    }
    useEffect(() => {
        reloadList();
    }, []
    );

    return (
        <div className={styles.main}>
            <MainCoverComponent>
                <PageHeader>{t('Companies')}</PageHeader>

                <SecondWindow>
                    <div className={styles.firstRaw}>


                        <SearchInput
                            value={search}
                            setValue={setSearch}
                        />
                    </div>

                    <div className={styles.cardsContainer}>
                        {
                            list.map((item, index) =>
                                <Link
                                    key={item.voen}
                                    to="/ListBuildings"
                                    className={styles.cardContainer}
                                >
                                    <div className={styles.cardTop}>
                                        <div
                                            className={index === 0 || (index / 10 + "").split(".")[1] === 0 ? styles.iconContainer :
                                                Number((index / 10 + "").split(".")[1]) === 1 || Number((index / 10 + "").split(".")[1]) === 6 ?
                                                    styles.iconContainerRed :
                                                    Number((index / 10 + "").split(".")[1]) === 2 || Number((index / 10 + "").split(".")[1]) === 7 ?
                                                        styles.iconContainerBlue :
                                                        Number((index / 10 + "").split(".")[1]) === 3 || Number((index / 10 + "").split(".")[1]) === 8 ?
                                                            styles.iconContainerOrange :
                                                            Number((index / 10 + "").split(".")[1]) === 5 ? styles.iconContainerLightBlue : styles.iconContainer
                                            }

                                        >
                                            <FontAwesomeIcon icon={faCity} className={styles.icon} />
                                        </div>
                                    </div>


                                    <div className={styles.cardTextContainer}>
                                        <p className={styles.textVoen}>{item.voen}</p>
                                    </div>
                                    <div className={styles.cardTextContainer}>
                                        <p className={styles.textCompany}>{item.companyName}</p>
                                    </div>
                                    <div className={styles.cardTextContainer}>
                                        <p className={styles.textNumber}>{item.number}</p>
                                    </div>

                                    <div className={styles.cardTextContainer}>
                                        <p className={styles.textMail}>{item.email}</p>
                                    </div>
                                </Link>
                            )
                        }
                    </div>

                </SecondWindow>
            </MainCoverComponent>
        </div>
    );
}

export default ListCompaniesFiltered;