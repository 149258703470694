import React, { useState, useEffect } from "react";
import axios from 'axios';
import url from "../../constants/url";

// Components
import ResponseMessage from "../ResponseMessage/ResponseMessage";
import ModalComponentAddAdmin from '../ModalComponentAddAdmin/ModalComponentAddAdmin';
import ModalComponentEditAdmin from '../ModalComponentEditAdmin/ModalComponentEditAdmin';
import ModalComponentAddCompany from '../ModalComponentAddCompany/ModalComponentAddCompany';
import ModalComponentEditCompany from '../ModalComponentEditCompany/ModalComponentEditCompany';
import ModalComponentAddBuilding from '../ModalComponentAddBuilding/ModalComponentAddBuilding';
import ModalComponentEditBuilding from '../ModalComponentEditBuilding/ModalComponentEditBuilding';
import ModalComponentAddBlock from '../ModalComponentAddBlock/ModalComponentAddBlock';
import ModalComponentEditBlock from '../ModalComponentEditBlok/ModalComponentEditBlok';
import ModalComponentAddElevator from '../ModalComponentAddElevator/ModalComponentAddElevator';
import ModalComponentEditElevator from '../ModalComponentEditElevator/ModalComponentEditElevator';
import ModalComponentAddUser from '../ModalComponentAddUser/ModalComponentAddUser';
import ModalComponentEditUser from '../ModalComponentEditUser/ModalComponentEditUser';
import ModalComponentAddCard from '../ModalComponentAddCard/ModalComponentAddCard';
import ModalComponentDeleteCard from '../ModalComponentDeleteCard/ModalComponentDeleteCard';
import ModalComponentEditCard from '../ModalComponentEditCard/ModalComponentEditCard';
import ModalComponentDeleteUser from '../ModalComponentDeleteUser/ModalComponentDeleteUser';
import ModalComponentUserPayments from '../ModalComponentUserPayments/ModalComponentUserPayments';
import ModalComponentRecover from '../ModalComponentRecover/ModalComponentRecover';


// Event Handlers
import addAdminHandler from '../../eventHandlers/addAdminHandler';
import editAdminHandler from '../../eventHandlers/editAdminHandler';
import deleteAdminHandler from "../../eventHandlers/deleteAdminHandler";
import addCompanyHandler from "../../eventHandlers/addCompanyHandler";
import editCompanyHandler from "../../eventHandlers/editCompanyHandler";
import deleteCompanyHandler from "../../eventHandlers/deleteCompanyHandler";
import addBuildingHandler from "../../eventHandlers/addBuildingHandler";
import editBuildingHandler from "../../eventHandlers/editBuildingHandler";
import deleteBuildingHandler from "../../eventHandlers/deleteBuildingHandler";
import addBlockHandler from "../../eventHandlers/addBlokHandler";
import editBlockHandler from "../../eventHandlers/editBlockHandler";
import deleteBlockHandler from "../../eventHandlers/deleteBlockHandler";
import addLiftHandler from "../../eventHandlers/addLiftHandler";
import editLiftHandler from "../../eventHandlers/editLiftHandler";
import deleteLiftHandler from "../../eventHandlers/deleteLiftHandler";
import addUserHandler from "../../eventHandlers/addUserHandler";
import editUserHandler from "../../eventHandlers/editUserHandler";
import deleteUserHandler from "../../eventHandlers/deleteUserHandler";
import addCardHandler from "../../eventHandlers/addCardHandler";
import deleteCardHandler from "../../eventHandlers/deleteCardHandler";
import editCardHandler from "../../eventHandlers/editCardHandler";
import recoverAdminHandler from "../../eventHandlers/recoverAdminHandler";
import recoverCompanyHandler from "../../eventHandlers/recoverCompanyHandler";
import recoverBuildingHandler from "../../eventHandlers/recoverBuildingHandler";
import recoverBlokHandler from "../../eventHandlers/recoverBlokHandler";
import recoverLiftHandler from "../../eventHandlers/recoverLiftHandler";
import recoverUserHandler from "../../eventHandlers/recoverUserHandler";
import { useTranslation } from 'react-i18next';


function ModalWindow({ reloadList, setModal, modalType, modalData,setResponseStatus }) {
    const { t } = useTranslation();
    const [data, setData] = useState({ ...modalData, days: 0, garage: false, isGlobalUsed: false })
    const [dataElevator, setDataElevator] = useState({ ...modalData, days: 0,  isGlobalUsed: false })
    const [loadedData, setLoadedData] = useState({ ...modalData })
    const modalDataOld = { ...modalData }
    const [companies, setCompanies] = useState([]);
    const [activeCompany, setActiveCompany] = useState("noFilter")
    const [activeBuilding, setActiveBuilding] = useState("noFilter")
    const [buildings, setBuildings] = useState([{ building: "noFilter", name: t('Building') }]);
    const [blocks, setBlocks] = useState([{ blok: "noFilter", name:  t('Entrance') }]);
    const [lifts, setLifts] = useState([]);
    const [checked, setChecked] = useState([]);


    const [message, setMessage] = useState("Wrong Password");
    const [success, setSuccess] = useState(false);
    const [hidden, setHidden] = useState(true);

    
    const handleCheckBoxChange = (index) => {
        let checkBoxArray = [];
        for (let i = 0; i < checked.length; i++) {
            if (i === index) {
                checkBoxArray.push({ status: !checked[i].status, lift: checked[i].lift })
            } else {
                checkBoxArray.push({ status: checked[i].status, lift: checked[i].lift })
            }

        }
        setChecked([...checkBoxArray]);
        setData({ ...data, liftIds: checkBoxArray })
    };

    useEffect(() => {
        // reloadList();
        if (modalType === "addBuilding" || modalType === "editBuilding") {
            axios({
                method: 'get',
                url: url + "/get_companies",
                headers: {
                    'authKey': localStorage.getItem("auth_key")
                }
            })
                .then(function (response) {
                    console.log("success")
                    console.log(response.data)
                    setCompanies([...response.data]);
                }).catch(function (error) {
                    console.log("fail")
                    console.log(error)
                });
        } else if (modalType === "addBlock" || modalType === "editBlock") {
            axios({
                method: 'get',
                url: url + "/get_companies",
                headers: {
                    'authKey': localStorage.getItem("auth_key")
                }
            })
                .then(function (response) {
                    console.log("success")
                    console.log(response.data)
                    setCompanies([...response.data]);
                }).catch(function (error) {
                    console.log("fail")
                    console.log(error)
                });

            axios({
                method: 'get',
                url: url + "/get_buildings",
                headers: {
                    'authKey': localStorage.getItem("auth_key")
                }
            })
                .then(function (response) {
                    console.log("success")
                    console.log(response.data)
                    setBuildings([{ building: "noFilter", name: t('Building') }, ...response.data]);
                }).catch(function (error) {
                    console.log("fail")
                    console.log(error)
                });
        } else if (modalType === "addElevator" || modalType === "editElevator") {
            axios({
                method: 'get',
                url: url + "/get_companies",
                headers: {
                    'authKey': localStorage.getItem("auth_key")
                }
            })
                .then(function (response) {
                    // console.log("success")
                    // console.log(response.data)
                    setCompanies([{ voen: "noFilter", companyName: t('Company') }, ...response.data]);
                }).catch(function (error) {
                    console.log("fail")
                    console.log(error)
                });
        } else if (modalType === "addUser" || modalType === "editUser") {
            if(localStorage.getItem("type") === "0" || localStorage.getItem("type") === "1"){
                axios({
                    method: 'get',
                    url: url + "/get_companies",
                    headers: {
                        'authKey': localStorage.getItem("auth_key")
                    }
                })
                    .then(function (response) {
                        console.log("success")
                        console.log(response.data)
                        setCompanies([{ voen: "noFilter", companyName: t('Company') }, ...response.data]);
                    }).catch(function (error) {
                        console.log("fail")
                        console.log(error)
                    });
            }else if (localStorage.getItem("type") === "2"){
                setActiveCompany(localStorage.getItem("voen"));
                axios({
                    method: 'get',
                    url: url + "/get_buildings_by_voen",
                    headers: {
                        'authKey': localStorage.getItem("auth_key"),
                        'voen': localStorage.getItem("voen")
                    }
                })
                    .then(function (response) {
                        console.log("success")
                        console.log(response.data)
                        setBuildings([{ building: "noFilter", name: t('Building') }, ...response.data]);
                    }).catch(function (error) {
                        console.log("fail")
                        console.log(error)
                    });
            }else{
                setActiveCompany(localStorage.getItem("voen"));
                setActiveBuilding(localStorage.getItem("building"));
                axios({

                    method: 'get',
                    url: url + "/get_bloks_by_buildingId",
                    headers: {
                        'authKey': localStorage.getItem("auth_key"),
                        'building': localStorage.getItem("building")
                    }
                })
                    .then(function (response) {
                        console.log("success buil;ding")
                        console.log(response.data)
                        setBlocks([{ blok: "noFilter", name: t('Entrance') }, ...response.data]);
                        
                    }).catch(function (error) {
                        console.log("fail")
                        console.log(error)
                    });
            }
        }
    }, [modalType]
    );

    function companySelectChangeHandler(e) {
        setData({ ...data, voen: e.target.value })
        setBuildings([{ building: "noFilter", name: t('Building') }])
        setBlocks([{ blok: "noFilter", name: t('Entrance') }])
        setLifts([])
        setChecked([]);
        setActiveBuilding("noFilter")
        setActiveCompany(e.target.value)
        console.log("inside company select change handler")
        if (e.target.value !== "noFilter") {
            axios({
                method: 'get',
                url: url + "/get_buildings_by_voen",
                headers: {
                    'authKey': localStorage.getItem("auth_key"),
                    'voen': e.target.value
                }
            })
                .then(function (response) {
                    console.log("success")
                    console.log(response.data)
                    setBuildings([{ building: "noFilter", name: t('Building') }, ...response.data]);
                }).catch(function (error) {
                    console.log("fail")
                    console.log(error)
                });
        }
    }


    function buildingSelectChangeHandler(e) {

        setData({ ...data, building: e.target.value })

        setBlocks([{ blok: "noFilter", name: t('Entrance') }])
        setLifts([]);
        setChecked([]);
        setActiveBuilding(e.target.value)


        if (e.target.value !== "noFilter") {
            axios({

                method: 'get',
                url: url + "/get_bloks_by_buildingId",
                headers: {
                    'authKey': localStorage.getItem("auth_key"),
                    'building': e.target.value
                }
            })
                .then(function (response) {
                    console.log("success")
                    console.log(response.data)
                    setBlocks([{ blok: "noFilter", name: t('Entrance') }, ...response.data]);
                }).catch(function (error) {
                    console.log("fail")
                    console.log(error)
                });
        }

    }

    function blockSelectChangeHandler(e) {

        setLifts([])
        setChecked([]);
        if (e.target.value !== "noFilter") {
            axios({

                method: 'get',
                url: url + "/get_lifts_by_blok",
                headers: {
                    'authKey': localStorage.getItem("auth_key"),
                    'blok': e.target.value
                }
            })
                .then(function (response) {
                    console.log("success")
                    console.log(response.data)
                    let checkBoxArray = [];
                    for (let i = 0; i < response.data.length; i++) {
                        checkBoxArray.push({ status: true, lift: response.data[i].lift })
                    }
                    setLifts([...response.data]);
                    setData({ ...data, blok: e.target.value, liftIds: checkBoxArray })
                    //lifts.lift
                    setChecked([...checkBoxArray])
                }).catch(function (error) {
                    console.log("fail")
                    console.log(error)
                });
        }
    }

    let modal;
    if (modalType === "addAdmin") {
        modal =
            <ModalComponentAddAdmin
                setModal={setModal}
                addAdminHandler={(event)=>addAdminHandler(event, data, setModal, reloadList,setResponseStatus,setSuccess,setMessage,setHidden)}
                data={data}
                setData={setData}
                
            />
    } else if (modalType === "editAdmin") {
        modal =
            <ModalComponentEditAdmin
                setModal={setModal}
                editAdminHandler={(event)=>editAdminHandler(event, modalDataOld, data, setModal, reloadList, setResponseStatus,setSuccess,setMessage,setHidden)}
                deleteAdminHandler={()=>deleteAdminHandler(modalDataOld,setModal, reloadList,setResponseStatus,setSuccess,setMessage,setHidden)}
                data={data}
                setData={setData}
            />
    } else if (modalType === "addCompany") {
        modal =
            <ModalComponentAddCompany
                setModal={setModal}
                addCompanyHandler={(event)=>addCompanyHandler(event, data, setModal, reloadList,setResponseStatus,setSuccess,setMessage,setHidden)}
                data={data}
                setData={setData}
            />
    } else if (modalType === "editCompany") {
        modal =
            <ModalComponentEditCompany
                setModal={setModal}
                editCompanyHandler={(event)=>editCompanyHandler(event,data, modalDataOld,setModal, reloadList,setResponseStatus,setSuccess,setMessage,setHidden)}
                deleteCompanyHandler={(event)=>deleteCompanyHandler( modalDataOld,setModal, reloadList,setResponseStatus,setSuccess,setMessage,setHidden)}
                data={data}
                setData={setData}
            />
    } else if (modalType === "addBuilding") {
        modal = <ModalComponentAddBuilding
            setModal={setModal}
            addBuildingHandler={(event)=>addBuildingHandler(event, data, setModal, reloadList,setResponseStatus,setSuccess,setMessage,setHidden)}
            data={data}
            setData={setData}
            companies={companies}
        />
    } else if (modalType === "editBuilding") {
        modal = <ModalComponentEditBuilding
            setModal={setModal}
            editBuildingHandler={(event)=>editBuildingHandler(event,data, modalData, setModal, reloadList,setSuccess,setResponseStatus,setMessage,setHidden)}
            deleteBuildingHandler={()=>deleteBuildingHandler(modalDataOld,setModal, reloadList,setResponseStatus,setSuccess,setMessage,setHidden)}
            data={data}
            setData={setData}
            companies={companies}
        />
    } else if (modalType === "addBlock") {
        modal = <ModalComponentAddBlock
            setModal={setModal}
            addBlockHandler={(event)=>addBlockHandler(event, data, setModal, reloadList,setResponseStatus,setSuccess,setMessage,setHidden)}
            data={data}
            setData={setData}
            companies={companies}
            companySelectChangeHandler={companySelectChangeHandler}
            activeCompany={activeCompany}
            buildings={buildings}
        />
    } else if (modalType === "editBlock") {
        modal = <ModalComponentEditBlock
            setModal={setModal}
            editBlockHandler={(e)=>editBlockHandler(e,data, modalDataOld, setModal, reloadList,setSuccess,setResponseStatus,setMessage,setHidden)}
            deleteBlockHandler={()=>deleteBlockHandler(modalDataOld, setModal, reloadList,setSuccess,setResponseStatus,setMessage,setHidden)}
            data={data}
            setData={setData}

        />
    } else if (modalType === "addElevator") {
        modal = <ModalComponentAddElevator
            setModal={setModal}
            addLiftHandler={(e)=>addLiftHandler(e, data, setModal, reloadList,setResponseStatus,setSuccess,setMessage,setHidden)}
            data={data}
            setData={setData}
            companySelectChangeHandler={companySelectChangeHandler}
            companies={companies}
            activeCompany={activeCompany}
            buildings={buildings}
            buildingSelectChangeHandler={buildingSelectChangeHandler}
            activeBuilding={activeBuilding}
            blocks={blocks}
        />
    } else if (modalType === "editElevator") {
        modal = <ModalComponentEditElevator
            setModal={setModal}
            editLiftHandler={(e)=>editLiftHandler(e, modalDataOld, dataElevator, setModal, reloadList, setResponseStatus,setSuccess,setMessage,setHidden)}
            deleteLiftHandler={()=>deleteLiftHandler( modalDataOld,setModal, reloadList,setResponseStatus,setSuccess,setMessage,setHidden)}
            data={dataElevator}
            setData={setDataElevator}

        />
    } else if (modalType === "addUser") {
        modal = <ModalComponentAddUser
            setModal={setModal}
            addUserHandler={(e)=>addUserHandler(e, data, setModal, reloadList,setResponseStatus,setSuccess,setMessage,setHidden)}
            data={data}
            setData={setData}
            companies={companies}
            buildingSelectChangeHandler={buildingSelectChangeHandler}
            blockSelectChangeHandler={blockSelectChangeHandler}
            activeBuilding={activeBuilding}
            blocks={blocks}
            lifts={lifts}
            handleCheckBoxChange={handleCheckBoxChange}
            checked={checked}
            buildings={buildings}
            companySelectChangeHandler={companySelectChangeHandler}
        />
    } else if (modalType === "editUser") {
        modal = <ModalComponentEditUser
            setModal={setModal}
            editUserHandler={(e)=>editUserHandler(e,data, loadedData, setModal, reloadList,setSuccess,setResponseStatus,setMessage,setHidden)}
            data={loadedData}
            setData={setLoadedData}
            companies={companies}
            buildingSelectChangeHandler={buildingSelectChangeHandler}
            blockSelectChangeHandler={blockSelectChangeHandler}
            activeBuilding={activeBuilding}
            blocks={blocks}
            lifts={lifts}
            handleCheckBoxChange={handleCheckBoxChange}
            checked={checked}
            buildings={buildings}
            companySelectChangeHandler={companySelectChangeHandler}
        />
    } else if (modalType === "deleteUser") {
        modal = <ModalComponentDeleteUser
            setModal={setModal}
            deleteUserHandler={(e)=>deleteUserHandler(e, data, setModal, reloadList,setResponseStatus,setSuccess,setMessage,setHidden)}
            data={{ ...data }}
        />
    } else if (modalType === "addCard") {
        modal = <ModalComponentAddCard
            setModal={setModal}
            addCardHandler={(e)=>addCardHandler(e, data,setModal,reloadList)}
            data={{ ...data }}
            setData={setData}
        />
    }else if (modalType === "deleteCard") {
        modal = <ModalComponentDeleteCard
            setModal={setModal}
            deleteCardHandler={(e)=>deleteCardHandler(e, data,setModal,reloadList)}
            data={{ ...data }}
        />
    }else if (modalType === "editCard") {
        modal = <ModalComponentEditCard
            setModal={setModal}
            editCardHandler={(e)=>editCardHandler(e,loadedData,setModal,reloadList, modalDataOld)}
            data={{ ...loadedData }}
            setData={setLoadedData}
        />
    }else if (modalType === "userPayments") {
        modal = <ModalComponentUserPayments
            setModal={setModal}
            data={{ ...loadedData }}
            reloadList={reloadList}
        />
    }else if (modalType === "recoverAdmin") {
        modal = <ModalComponentRecover
            setModal={setModal}
            recover={(e)=>recoverAdminHandler(e, data, setModal,reloadList)}
            data={{ ...data }}
        />
    }else if (modalType === "recoverCompany") {
        modal = <ModalComponentRecover
            setModal={setModal}
            recover={(e)=>recoverCompanyHandler(e, data, setModal,reloadList)}
            data={{ ...data }}
        />
    }else if (modalType === "recoverBuilding") {
        modal = <ModalComponentRecover
            setModal={setModal}
            recover={(e)=>recoverBuildingHandler(e, data, setModal,reloadList)}
            data={{ ...data }}
        />
    }else if (modalType === "recoverBlok") {
        modal = <ModalComponentRecover
            setModal={setModal}
            recover={(e)=>recoverBlokHandler(e, data, setModal,reloadList)}
            data={{ ...data }}
        />
    }else if (modalType === "recoverLift") {
        modal = <ModalComponentRecover
            setModal={setModal}
            recover={(e)=>recoverLiftHandler(e, data, setModal, reloadList)}
            data={{ ...data }}
        />
    }else if (modalType === "recoverUser") {
        modal = <ModalComponentRecover
            setModal={setModal}
            recover={(e)=>recoverUserHandler(e, data, setModal, reloadList)}
            data={{ ...data }}
        />
    }
    
    return (
        <div>
            {modal}
            <ResponseMessage hidden={hidden} success={success} message={message}/>
        </div>
    )
}

export default ModalWindow;