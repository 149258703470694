import styles from './Index.module.css';
import React, { useState, useEffect } from "react";
import PageHeader from '../PageHeader/PageHeader';
import SecondWindow from '../SecondWindow/SecondWindow';
import ModalWindow from '../ModalWindow/ModalWindow';
import axios from 'axios';
import url from "../../constants/url";
import MainCoverComponent from "../MainCoverComponent/MainCoverComponent";
import { useTranslation } from 'react-i18next';

function RecycleBloks() {
    const [list, setList] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalData, setModalData] = useState(null);
    const { t } = useTranslation();
    function reloadList() {
        axios({
            method: 'get',
            url: url + "/get_bloks_for_recover",
            headers: {
                'authKey': localStorage.getItem("auth_key")
            }
        })
            .then(function (response) {
                console.log("success")
                console.log(response.data)
                setList([...response.data]);
            }).catch(function (error) {
                console.log("fail")
                console.log(error)
                setList([])
            });
    }

    useEffect(() => {
        reloadList();
    }, []
    );

    return (
        <div className={styles.main}>

            {modal ? <ModalWindow
                modalType={modalType}
                modalData={{ ...modalData }}
                setModal={setModal}
                reloadList={() => reloadList()}
            /> : null}

            <MainCoverComponent>
                <PageHeader>{t('Recover Entrance')} </PageHeader>

                <SecondWindow>
                    <div className={styles.contentContainer}>

                        <div className={styles.dataListTitlesContainer}>
                            <div className={styles.singleTitleContainer}>
                                <p className={styles.title}>{t('Entrance Id')}</p>
                            </div>
                            <div className={styles.singleTitleContainer}>
                                <p className={styles.title}>{t('Entrance Name')}</p>
                            </div>
                            <div className={styles.singleTitleContainer}>
                                <p className={styles.title}>{t('Building Id')}</p>
                            </div>
                            <div className={styles.singleTitleContainer}>
                                <p className={styles.title}>{t('Floor')}</p>
                            </div>
                            <div className={styles.singleTitleContainer}>
                                <p className={styles.title}>{t('Ip')}</p>
                            </div>
                        </div>



                        {
                            list.map(item =>
                                <div
                                    key={item.voen}
                                    onClick={() => {
                                        setModalType("recoverBlok")
                                        setModalData({ ...item })
                                        setModal(true)
                                    }}
                                    className={styles.tableRow}
                                >
                                    <div className={styles.singleTitleContainer}>
                                        <p className={styles.title}>{item.blok}</p>
                                    </div>
                                    <div className={styles.singleTitleContainer}>
                                        <p className={styles.title}>{item.name}</p>
                                    </div>
                                    <div className={styles.singleTitleContainer}>
                                        <p className={styles.title}>{item.building}</p>
                                    </div>
                                    <div className={styles.singleTitleContainer}>
                                        <p className={styles.title}>{item.floor}</p>
                                    </div>
                                    <div className={styles.singleTitleContainer}>
                                        <p className={styles.title}>{item.mac}</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </SecondWindow>
            </MainCoverComponent>
        </div>
    );
}

export default RecycleBloks;