import styles from './Index.module.css';
import React, { useState, useEffect } from "react";
import logo from '../../assets/logoLiftBig.png';
import Loading from '../../components/Loading/Loading';
function Login({handleSubmit, setAuth}) {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [autoLoginActivation, setAutoLoginActivation] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setTimeout(()=>setLoading(false), 1200)
        setTimeout(()=>setAutoLoginActivation(true), 500)
    }, []
    );

    function handleUsernameChange(e) {
        if(autoLoginActivation && userName.length===0 && e.target.value.length - userName.length > 3 && password.length!==0){
            setUserName(e.target.value)
            handleSubmit(null,e.target.value, password)
        }else{
            setUserName(e.target.value)
        }
    }
    function handlePasswordChange(e) {
        if(autoLoginActivation && password.length===0 && e.target.value.length - password.length > 3 && userName.length!==0){
            setPassword(e.target.value)
            handleSubmit(null,userName, e.target.value)
        }else{
            setPassword(e.target.value)
        }

    }


    return (
        <div className={styles.main}>
                { loading?<Loading size="big"/> : null}
            <div className={styles.container}>
                <img src={logo} alt="Logo" className={styles.logo} />
                <div className={styles.input_container}>
                    <form onSubmit={(e) => handleSubmit(e, userName, password)}>
                        <input
                            className={styles.input}
                            value={userName}
                            placeholder='Username'
                            onChange={e => handleUsernameChange(e)}
                        />

                        <input
                            className={styles.input}
                            value={password}
                            placeholder='Password'
                            type='password'
                            onChange={e => handlePasswordChange(e)}
                        />
                        <button
                            className={styles.button}
                            value="text" >
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            Log In
                        </button>


                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;