import React from "react";
import styles from './Index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';

const ModalComponentEditAdmin = ({ setModal, editAdminHandler, deleteAdminHandler, data, setData }) => {

    const { t } = useTranslation();

    return (
        <div className={styles.modalWindow}>
            <div className={styles.modalContainer}>
                <p className={styles.modalTitle}>{t('Edit Admin')}</p>
                <FontAwesomeIcon onClick={() => setModal(false)} icon={faXmark} className={styles.icon} />
                <form onSubmit={(e) => editAdminHandler(e)} className={styles.modalInputsContainer}>
                    <input
                        className={styles.modalInput}
                        value={data.employee}
                        placeholder='Id'
                        type='text'
                        onChange={e => setData({ ...data, employee: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.name}
                        placeholder={t('Name')}
                        type='text'
                        onChange={e => setData({ ...data, name: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.surname}
                        placeholder={t('Surname')}
                        type='text'
                        onChange={e => setData({ ...data, surname: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.father_name}
                        placeholder={t('Father')}
                        type='text'
                        onChange={e => setData({ ...data, father_name: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.password}
                        placeholder={t('Password')}
                        type='text'
                        onChange={e => setData({ ...data, password: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.phone_number}
                        placeholder={t('Number')}
                        type='text'
                        onChange={e => setData({ ...data, phone_number: e.target.value })}
                    />

                    <button
                        className={styles.button}
                        value="text" >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        {t('Save')}
                    </button>
                </form>
                <Button type={"cancel"}
                    placeholder={t('Delete Admin')}
                    action={() => {
                        deleteAdminHandler()
                        setModal(false)
                    }}
                />

            </div>
        </div>
    );
};
export default ModalComponentEditAdmin;