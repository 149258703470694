import React from "react";
import styles from './Index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button/Button';
import Switch from '../Switch/Switch';
import { useTranslation } from 'react-i18next';
const ModalComponentEditUser = ({
    setModal,
    editUserHandler,
    data,
    setData
}) => {
    const { t } = useTranslation();
    return (
        <div className={styles.modalWindow}>
            {console.log("data")}
            {console.log(data)}
            <div className={styles.modalContainer}>
                <p className={styles.modalTitle}>{t('Edit User')}</p>
                <FontAwesomeIcon onClick={() => setModal(false)} icon={faXmark} className={styles.icon} />
                <form onSubmit={(e) => editUserHandler(e)} className={styles.modalInputsContainer}>
                    
                    <input
                        className={styles.modalInput}
                        value={data.fin}
                        placeholder={t('Id')}
                        type='text'
                        onChange={e => setData({ ...data, fin: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.name}
                        placeholder={t('Name')}
                        type='text'
                        onChange={e => setData({ ...data, name: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.surname}
                        placeholder={t('Surname')}
                        type='text'
                        onChange={e => setData({ ...data, surname: e.target.value })}
                    />

                    <input
                        className={styles.modalInput}
                        value={data.fatherName}
                        placeholder={t('Father')}
                        type='text'
                        onChange={e => setData({ ...data, fatherName: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.number}
                        placeholder={t('Number')}
                        type='text'
                        onChange={e => setData({ ...data, number: e.target.value })}
                    />
                    {/* <div className={styles.selectWrapper}>
                        <select onChange={e => companySelectChangeHandler(e)} className={styles.select}>
                            {companies.map(item => <option key={item.voen} value={item.voen}>{item.companyName}</option>)}
                        </select>
                    </div>
                    <div className={styles.selectWrapper}>
                        <select disabled={activeCompany === "noFilter"} onChange={e => buildingSelectChangeHandler(e)} className={styles.select}>
                            {buildings.map(item => <option key={item.building} value={item.building}>{item.name}</option>)}
                        </select>
                    </div>
                    <div className={styles.selectWrapper}>
                        <select disabled={activeBuilding === "noFilter"} onChange={e => blockSelectChangeHandler(e)} className={styles.select}>
                            {blocks.map(item => <option key={item.blok} value={item.blok}>{item.name}</option>)}
                        </select>
                    </div> */}

                    {/* {


                        lifts.map((item, index) =>
                            <div className={styles.checkBoxContainer}>
                                <input
                                    onChange={() => handleCheckBoxChange(index)}
                                    checked={checked[index].status}
                                    value={item.liftName}
                                    type="checkbox"
                                    className={styles.checkBox}
                                ></input>
                                <p className={styles.checkBoxLabel}>{item.liftName}</p>
                            </div>
                        )

                    } */}
                    <input
                        className={styles.modalInput}
                        value={data.floor}
                        placeholder={t('Floor')}
                        type='text'
                        onChange={e => setData({ ...data, floor: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.apartment}
                        placeholder={t('Apartment')}
                        type='text'
                        onChange={e => setData({ ...data, apartment: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.dueDate}
                        placeholder={t('Due Date')}
                        type='date'
                        onChange={e => setData({ ...data, dueDate: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.payment}
                        placeholder={t('Payment')}
                        type='text'
                        onChange={e => setData({ ...data, payment: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.balance}
                        placeholder={t('Balance')}
                        type='text'
                        onChange={e => setData({ ...data, balance: e.target.value })}
                    />
                    <div className={styles.selectWrapper}>
                        <select onChange={e => setData({ ...data, days: e.target.value })} className={styles.select}>
                            <option value={0}>{t('Monthly')}</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={13}>13</option>
                            <option value={14}>14</option>
                            <option value={15}>15</option>
                            <option value={16}>16</option>
                            <option value={17}>17</option>
                            <option value={18}>18</option>
                            <option value={19}>19</option>
                            <option value={20}>20</option>
                            <option value={21}>21</option>
                            <option value={22}>22</option>
                            <option value={23}>23</option>
                            <option value={24}>24</option>
                            <option value={25}>25</option>
                            <option value={26}>26</option>
                            <option value={27}>27</option>
                            <option value={28}>28</option>
                            <option value={29}>29</option>
                            <option value={30}>30</option>
                            <option value={31}>31</option>
                        </select>
                    </div>
                    <div className={styles.switchContainer}>
                        <p className={styles.switchLabel}>{t('Garage')}</p>
                        <Switch value={data.garage === 1 ? true : false} onClick={swicthValue => setData({ ...data, garage: swicthValue ? 1 : 0 })} />
                    </div>
                    <button
                        className={styles.button}
                        value="text" >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        {t('Save')}
                    </button>
                    <Button type={"cancel"}
                        placeholder={t('Cancel')}
                        action={() => setModal(false)}
                    />
                </form>
            </div>
        </div>
    );
};
export default ModalComponentEditUser;