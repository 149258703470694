import styles from './Index.module.css';
import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTie, faCity, faList, faAngleRight, faBuilding, faUsers, faCircleQuestion, faChartPie, faClipboard, faElevator, faGears, faMapLocation, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function LeftMenu({ activeMenu, setActiveMenu }) {
    const [activeList, setActiveList] = useState(0);
    const { t } = useTranslation();


    return (
        <div className={activeMenu ? styles.main_open : styles.main}>
            <div className={styles.leftMenuList}>
                {localStorage.getItem("type") === "0" ?
                    <Link onClick={() => setActiveMenu(false)} className={styles.leftMenuListItem} to="/listAdmins">
                        <div className={styles.pointer}></div>
                        <div className={styles.iconContainer} >
                            <FontAwesomeIcon icon={faUserTie} className={styles.icon} />
                        </div>
                        <p className={styles.leftItemText}>{t('Admins')}</p>
                    </Link>
                    : null
                }


                {
                    localStorage.getItem("type") === "0" || localStorage.getItem("type") === "1" ?
                        <div onClick={() => activeList === 2 ? setActiveList(0) : setActiveList(2)} className={styles.leftMenuListItem}>
                            <div className={styles.pointer}></div>
                            <div className={styles.iconContainer} >
                                <FontAwesomeIcon icon={faCity} className={styles.icon} />
                            </div>
                            <p className={styles.leftItemText}>{t('Companies')}</p>

                            <FontAwesomeIcon icon={faAngleRight} className={activeList === 2 ? styles.arrowTurned : styles.rightArrow} />
                        </div>
                        : null
                }


                <div className={activeList === 2 ? styles.subdirectory : styles.closed}>
                    <Link onClick={() => setActiveMenu(false)} className={styles.reset} to="/Companies">
                        <div className={styles.subList}>
                            <div className={styles.subPointer}></div>
                            <div className={styles.subIconContainer} >
                                <FontAwesomeIcon icon={faList} className={styles.subIcon} />
                            </div>
                            <p className={styles.leftSubText}>{t('Companies List')}</p>
                        </div>
                    </Link>
                    <Link onClick={() => setActiveMenu(false)} className={styles.reset} to="/companySettings">
                        <div className={styles.subList}>
                            <div className={styles.subPointer}></div>
                            <div className={styles.subIconContainer} >
                                <FontAwesomeIcon icon={faGears} className={styles.subIcon} />
                            </div>
                            <p className={styles.leftSubText}>{t('Company Settings')}</p>
                        </div>
                    </Link>
                </div>

                {
                    localStorage.getItem("type") === "0" || localStorage.getItem("type") === "1" ?
                        <div onClick={() => activeList === 3 ? setActiveList(0) : setActiveList(3)} className={styles.leftMenuListItem}>
                            <div className={styles.pointer}></div>
                            <div className={styles.iconContainer} >
                                <FontAwesomeIcon icon={faBuilding} className={styles.icon} />
                            </div>
                            <p className={styles.leftItemText}>{t('Buildings')}</p>
                            <FontAwesomeIcon icon={faAngleRight} className={activeList === 3 ? styles.arrowTurned : styles.rightArrow} />
                        </div>


                        : localStorage.getItem("type") === "2" ?
                            <Link onClick={() => setActiveMenu(false)} className={styles.leftMenuListItem} to="/ListBuildings">
                                <div className={styles.pointer}></div>
                                <div className={styles.iconContainer} >
                                    <FontAwesomeIcon icon={faBuilding} className={styles.icon} />
                                </div>
                                <p className={styles.leftItemText}>{t('Buildings')}</p>
                            </Link>
                            : null
                }


                {localStorage.getItem("type") === "0" || localStorage.getItem("type") === "1" ?
                    <div className={activeList === 3 ? styles.subdirectory : styles.closed}>
                        <Link onClick={() => setActiveMenu(false)} className={styles.reset} to="/ListBuildings">
                            <div className={styles.subList}>
                                <div className={styles.subPointer}></div>
                                <div className={styles.subIconContainer} >
                                    <FontAwesomeIcon icon={faList} className={styles.subIcon} />
                                </div>
                                <p className={styles.leftSubText}>{t('Buildings List')}</p>
                            </div>
                        </Link>
                        <Link onClick={() => setActiveMenu(false)} className={styles.reset} to="/EditBuildings">
                            <div className={styles.subList}>
                                <div className={styles.subPointer}></div>
                                <div className={styles.subIconContainer} >
                                    <FontAwesomeIcon icon={faGears} className={styles.subIcon} />
                                </div>
                                <p className={styles.leftSubText}>{t('Buildings Settings')}</p>
                            </div>
                        </Link>
                    </div> : null
                }




                {
                    localStorage.getItem("type") === "0" || localStorage.getItem("type") === "1" ?
                        <div onClick={() => activeList === 4 ? setActiveList(0) : setActiveList(4)} className={styles.leftMenuListItem}>
                            <div className={styles.pointer}></div>
                            <div className={styles.iconContainer} >
                                <FontAwesomeIcon icon={faClipboard} className={styles.icon} />
                            </div>
                            <p className={styles.leftItemText}>{t('Entrances')}</p>
                            <FontAwesomeIcon icon={faAngleRight} className={activeList === 4 ? styles.arrowTurned : styles.rightArrow} />
                        </div>


                        :
                        <Link onClick={() => setActiveMenu(false)} className={styles.reset} to="/ListBlocks">
                            <div className={styles.leftMenuListItem}>
                                <div className={styles.pointer}></div>
                                <div className={styles.iconContainer} >
                                    <FontAwesomeIcon icon={faClipboard} className={styles.icon} />
                                </div>
                                <p className={styles.leftItemText}>{t('Entrances')}</p>
                            </div>
                        </Link>
                }


                {localStorage.getItem("type") === "0" || localStorage.getItem("type") === "1" ?
                    <div className={activeList === 4 ? styles.subdirectory : styles.closed}>
                        <Link onClick={() => setActiveMenu(false)} className={styles.reset} to="/ListBlocks">
                            <div className={styles.subList}>
                                <div className={styles.subPointer}></div>
                                <div className={styles.subIconContainer} >
                                    <FontAwesomeIcon icon={faList} className={styles.subIcon} />
                                </div>
                                <p className={styles.leftSubText}>{t('Entrances List')}</p>
                            </div>
                        </Link>
                        <Link onClick={() => setActiveMenu(false)} className={styles.reset} to="/EditBlocks">
                            <div className={styles.subList}>
                                <div className={styles.subPointer}></div>
                                <div className={styles.subIconContainer} >
                                    <FontAwesomeIcon icon={faGears} className={styles.subIcon} />
                                </div>
                                <p className={styles.leftSubText}>{t('Entrances Settings')}</p>
                            </div>
                        </Link>
                    </div> : null
                }



                {
                    localStorage.getItem("type") === "0" || localStorage.getItem("type") === "1" ?
                        <div onClick={() => activeList === 5 ? setActiveList(0) : setActiveList(5)} className={styles.leftMenuListItem}>
                            <div className={styles.pointer}></div>
                            <div className={styles.iconContainer} >
                                <FontAwesomeIcon icon={faElevator} className={styles.icon} />
                            </div>
                            <p className={styles.leftItemText}>{t('Elevators')}</p>
                            <FontAwesomeIcon icon={faAngleRight} className={activeList === 5 ? styles.arrowTurned : styles.rightArrow} />
                        </div>


                        :

                        <Link onClick={() => setActiveMenu(false)} className={styles.reset} to="/ListElevators">
                            <div className={styles.leftMenuListItem}>
                                <div className={styles.pointer}></div>
                                <div className={styles.iconContainer} >
                                    <FontAwesomeIcon icon={faElevator} className={styles.icon} />
                                </div>
                                <p className={styles.leftItemText}>{t('Elevators')}</p>
                            </div>
                        </Link>
                }


                {localStorage.getItem("type") === "0" || localStorage.getItem("type") === "1" ?
                    <div className={activeList === 5 ? styles.subdirectory : styles.closed}>
                        <Link onClick={() => setActiveMenu(false)} className={styles.reset} to="/ListElevators">
                            <div className={styles.subList}>
                                <div className={styles.subPointer}></div>
                                <div className={styles.subIconContainer} >
                                    <FontAwesomeIcon icon={faList} className={styles.subIcon} />
                                </div>
                                <p className={styles.leftSubText}>{t('Elevators List')}</p>
                            </div>
                        </Link>
                        <Link onClick={() => setActiveMenu(false)} className={styles.reset} to="/EditElevators">
                            <div className={styles.subList}>
                                <div className={styles.subPointer}></div>
                                <div className={styles.subIconContainer} >
                                    <FontAwesomeIcon icon={faGears} className={styles.subIcon} />
                                </div>
                                <p className={styles.leftSubText}>{t('Elevators Settings')}</p>
                            </div>
                        </Link>
                    </div> : null
                }
                <Link onClick={() => setActiveMenu(false)} className={styles.leftMenuListItem} to="/">
                    <div className={styles.pointer}></div>
                    <div className={styles.iconContainer} >
                        <FontAwesomeIcon icon={faUsers} className={styles.icon} />
                    </div>
                    <p className={styles.leftItemText}>{t('Users')}</p>
                </Link>
                <Link onClick={() => setActiveMenu(false)} className={styles.leftMenuListItem} to="/Monitoring">
                    <div className={styles.pointer}></div>
                    <div className={styles.iconContainer} >
                        <FontAwesomeIcon icon={faChartPie} className={styles.icon} />
                    </div>
                    <p className={styles.leftItemText}>{t('Monitoring')}</p>
                </Link>
                <Link onClick={() => setActiveMenu(false)} className={styles.leftMenuListItem} to="/MapView">
                    <div className={styles.pointer}></div>
                    <div className={styles.iconContainer} >
                        <FontAwesomeIcon icon={faMapLocation} className={styles.icon} />
                    </div>
                    <p className={styles.leftItemText}>{t('Map')}</p>
                </Link>
                {
                    localStorage.getItem("type") === "0" ?
                        <Link onClick={() => setActiveMenu(false)} className={styles.leftMenuListItem} to="/Recycle">
                            <div className={styles.pointer}></div>
                            <div className={styles.iconContainer} >
                                <FontAwesomeIcon icon={faTrashCan} className={styles.icon} />
                            </div>
                            <p className={styles.leftItemText}>{t('Recycle Bin')}</p>
                        </Link> : null

                }

                <Link onClick={() => setActiveMenu(false)} className={styles.leftMenuListItem} to="/Support">
                    <div className={styles.pointer}></div>
                    <div className={styles.iconContainer} >
                        <FontAwesomeIcon icon={faCircleQuestion} className={styles.icon} />
                    </div>
                    <p className={styles.leftItemText}>{t('App Support')}</p>
                </Link>
            </div>

        </div>
    );
}

export default LeftMenu; 