import styles from './Index.module.css';
import React, { useState, useEffect } from "react";
import ModalWindow from '../ModalWindow/ModalWindow';
import PageHeader from '../PageHeader/PageHeader';
import SecondWindow from '../SecondWindow/SecondWindow';
import axios from 'axios';
import url from "../../constants/url";
import Loading from '../Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faCreditCard, faDollarSign, faGear, faCheck, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import ResponseMessage from "../ResponseMessage/ResponseMessage";
import MainCoverComponent from "../MainCoverComponent/MainCoverComponent";
import AddButton from '../AddButton/AddButton';
import SearchInput from "../SearchInput/SearchInput";
import { useTranslation } from 'react-i18next';
function ListUsers() {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalData, setModalData] = useState(null);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);

    const [activeUser, setActiveUser] = useState();

    const [responseStatus, setResponseStatus] = useState(false);

    function activateUserHandler(id) {
        setActiveUser(id);
        if (id !== 0) {
            setLoading(true);
            setTimeout(() => setLoading(false), 750);
        }

    }
    function reloadList() {
        setTimeout(() => setResponseStatus(false), 2000)
        if (localStorage.getItem("type") === "3") {
            console.log(localStorage.getItem("voen"))
            axios({
                method: 'get',
                url: url + "/get_users_by_building",
                headers: {
                    'authKey': localStorage.getItem("auth_key"),
                    'building': localStorage.getItem("building")
                }
            })
                .then(function (response) {
                    // console.log("success")
                    setList([...response.data]);
                }).catch(function (error) {
                    // console.log("fail")
                });
        } else if (localStorage.getItem("type") === "2") {
            console.log(localStorage.getItem("voen"))
            axios({
                method: 'get',
                url: url + "/get_users_by_voen",
                headers: {
                    'authKey': localStorage.getItem("auth_key"),
                    'voen': localStorage.getItem("voen")
                }
            })
                .then(function (response) {
                    // console.log("success")
                    setList([...response.data]);
                }).catch(function (error) {
                    // console.log("fail")
                });
        } else {
            axios({
                method: 'get',
                url: url + "/get_users",
                headers: {
                    'authKey': localStorage.getItem("auth_key")
                }
            })
                .then(function (response) {
                    // console.log("success")
                    setList([...response.data]);
                }).catch(function (error) {
                    // console.log("fail")
                });
        }
    }
    useEffect(() => {
        reloadList();
    }, []
    );
    return (
        <div className={styles.main}>

            {modal ? <ModalWindow
                modalType={modalType}
                modalData={{ ...modalData }}
                setModal={setModal}
                reloadList={() => reloadList()}
                setResponseStatus={() => setResponseStatus(true)}
            /> : null}

            <MainCoverComponent>
                <PageHeader>{t('Users List')}</PageHeader>

                <SecondWindow>

                    <div className={styles.firstRaw}>
                        <AddButton
                            type={"addUser"}
                            setModalType={setModalType}
                            setModalData={setModalData}
                            setModal={setModal}
                        />

                        <SearchInput
                            value={search}
                            setValue={setSearch}
                        />
                    </div>


                    <div className={styles.dataListTitlesContainer}>
                        <div className={styles.singleTitleContainer}>
                            <p className={styles.title}>{t('Id')}</p>
                        </div>
                        <div className={styles.singleTitleContainer}>
                            <p className={styles.title}>{t('Surname')}</p>
                        </div>
                        <div className={styles.singleTitleContainer}>
                            <p className={styles.title}>{t('Name')}</p>
                        </div>
                        <div className={styles.singleTitleContainer}>
                            <p className={styles.title}>{t('Father')}</p>
                        </div>
                        <div className={styles.singleTitleContainer}>
                            <p className={styles.title}>{t('Status')}</p>
                        </div>
                        <div className={styles.singleTitleContainer}>
                            <p className={styles.title}>{t('Balance')}</p>
                        </div>

                        <div className={styles.singleTitleContainer}>
                            <p className={styles.title}>{t('Garage')}</p>
                        </div>


                    </div>
                    {
                        list.map(item =>
                            <div
                                key={item.fin}
                                className={activeUser === item.user ? styles.tableRowContainerOpened : styles.tableRowContainer}
                            >
                                <div
                                    onClick={() => { activeUser === item.user ? activateUserHandler(0) : activateUserHandler(item.user) }}
                                    className={styles.tableRow}>
                                    <div className={styles.singleTitleContainer}>
                                        <p className={styles.title}>{item.fin}</p>
                                    </div>
                                    <div className={styles.singleTitleContainer}>
                                        <p className={styles.title}>{item.surname}</p>
                                    </div>
                                    <div className={styles.singleTitleContainer}>
                                        <p className={styles.title}>{item.name}</p>
                                    </div>
                                    <div className={styles.singleTitleContainer}>
                                        <p className={styles.title}>{item.fatherName}</p>
                                    </div>
                                    <div className={styles.singleTitleContainer}>
                                        <div className={item.balance >= 0 ? styles.active : styles.deactive}></div>
                                    </div>
                                    <div className={styles.singleTitleContainer}>
                                        <p className={styles.title}>{item.balance}</p>
                                    </div>
                                    <div className={styles.singleTitleContainer}>
                                        <p className={styles.title}>{item.garage ? <FontAwesomeIcon icon={faCheck} className={styles.iconYes} /> : <FontAwesomeIcon icon={faXmark} className={styles.iconX} />}</p>
                                    </div>
                                </div>

                                {activeUser === item.user ?
                                    <div className={
                                        activeUser === item.user ? styles.userInfoContainer
                                            : styles.userInfoContainerClosed
                                    }>
                                        {loading ? <Loading size={"small"} /> :
                                            <div className={styles.userInfoContainerFlex}>
                                                <div className={styles.singleInfoContainer}>
                                                    <div className={styles.singleInfoTitle}>
                                                        <p>{t('Registration Date')}:</p>
                                                    </div>
                                                    <div className={styles.singleInfoContent}>
                                                        <p>{item.regDate}</p>
                                                    </div>
                                                </div>
                                                <div className={styles.singleInfoContainer}>
                                                    <div className={styles.singleInfoTitle}>
                                                        <p>{t('Number')}:</p>
                                                    </div>
                                                    <div className={styles.singleInfoContent}>
                                                        <p>{item.number}</p>
                                                    </div>
                                                </div>
                                                <div className={styles.singleInfoContainer}>
                                                    <div className={styles.singleInfoTitle}>
                                                        <p>{t('Company')}:</p>
                                                    </div>
                                                    <div className={styles.singleInfoContent}>
                                                        <p>{item.companyName}</p>
                                                    </div>
                                                </div>
                                                <div className={styles.singleInfoContainer}>
                                                    <div className={styles.singleInfoTitle}>
                                                        <p>{t('Building')}:</p>
                                                    </div>
                                                    <div className={styles.singleInfoContent}>
                                                        <p>{item.building_name}</p>
                                                    </div>
                                                </div>
                                                <div className={styles.singleInfoContainer}>
                                                    <div className={styles.singleInfoTitle}>
                                                        <p>{t('Entrance')}:</p>
                                                    </div>
                                                    <div className={styles.singleInfoContent}>
                                                        <p>{item.blok_name}</p>
                                                    </div>
                                                </div>
                                                <div className={styles.singleInfoContainer}>
                                                    <div className={styles.singleInfoTitle}>
                                                        <p>{t('Floor')}:</p>
                                                    </div>
                                                    <div className={styles.singleInfoContent}>
                                                        <p>{item.floor}</p>
                                                    </div>
                                                </div>
                                                <div className={styles.singleInfoContainer}>
                                                    <div className={styles.singleInfoTitle}>
                                                        <p>{t('Apartment')}:</p>
                                                    </div>
                                                    <div className={styles.singleInfoContent}>
                                                        <p>{item.apartment}</p>
                                                    </div>
                                                </div>
                                                <div className={styles.singleInfoContainer}>
                                                    <div className={styles.singleInfoTitle}>
                                                        <p>{t('User Id')}:</p>
                                                    </div>
                                                    <div className={styles.singleInfoContent}>
                                                        <p>{item.user}</p>
                                                    </div>
                                                </div>
                                                <div className={styles.singleInfoContainer}>
                                                    <div className={styles.singleInfoTitle}>
                                                        <p>{t('Payment Due Date')}:</p>
                                                    </div>
                                                    <div className={styles.singleInfoContent}>
                                                        <p>{item.dueDate}</p>
                                                    </div>
                                                </div>
                                                <div className={styles.singleInfoContainer}>
                                                    <div className={styles.singleInfoTitle}>
                                                        <p>{t('Payment')}:</p>
                                                    </div>
                                                    <div className={styles.singleInfoContent}>
                                                        <p>{item.payment}</p>
                                                    </div>
                                                </div>

                                                {
                                                    localStorage.getItem("type") === "0" || localStorage.getItem("type") === "0" ?
                                                        <div onClick={() => {
                                                            setModalType("editUser")
                                                            setModalData(item)
                                                            setModal(true)
                                                        }} className={styles.buttonUserContainer}>
                                                            <div className={styles.buttonIcon}>
                                                                <FontAwesomeIcon icon={faGear} className={styles.icon} />
                                                            </div>
                                                            <div className={styles.buttonLabel}>
                                                            {t('Edit')}
                                                            </div>

                                                        </div>
                                                        : null
                                                }

                                                <div
                                                    onClick={() => {
                                                        setModalType("userPayments")
                                                        setModalData(item)
                                                        setModal(true)
                                                    }} className={styles.buttonUserContainer}>
                                                    <div className={styles.buttonIcon}>
                                                        <FontAwesomeIcon icon={faDollarSign} className={styles.icon} />
                                                    </div>
                                                    <div className={styles.buttonLabel}>
                                                    {t('Payments')}
                                                    </div>
                                                </div>

                                                <Link className={styles.buttonUserContainer} to='/Cards' state={item} >
                                                    <div className={styles.buttonIcon}>

                                                        <FontAwesomeIcon icon={faCreditCard} className={styles.icon} />
                                                    </div>
                                                    <div className={styles.buttonLabel}>
                                                    {t('Cards')}
                                                    </div>
                                                </Link>
                                                {
                                                    localStorage.getItem("type") === "0" || localStorage.getItem("type") === "1" ?
                                                        <div
                                                            onClick={() => {
                                                                setModalType("deleteUser")
                                                                setModalData(item)
                                                                setModal(true)
                                                            }}
                                                            className={styles.buttonUserContainerDelete} to='/Cards' state={item} >

                                                            <div className={styles.buttonIcon}>

                                                                <FontAwesomeIcon icon={faTrashCan} className={styles.icon} />
                                                            </div>
                                                            <div className={styles.buttonLabel}>
                                                            {t('Delete User')}
                                                            </div>
                                                        </div>
                                                        : null
                                                }

                                            </div>
                                        }

                                    </div> : null}

                            </div>
                        )
                    }
                </SecondWindow>
            </MainCoverComponent>
            <ResponseMessage hidden={!responseStatus} success={"success"} message={modalType === "addUser" ? "User Added Successfully" : "User Saved Successfully"} />
        </div>
    );
}

export default ListUsers;