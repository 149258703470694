import { useEffect} from "react";
import styles from './Index.module.css';

function ResponseMessage({success, message, hidden}) {
    useEffect(() => {
    }, []);
    if(hidden){
        if(success){
            return (
                <div className={styles.containerSuccessHidden}>
                    <p>{message}</p>
                </div>
            );
        }
        else{
            return (
                <div className={styles.containerFailHidden}>
                    <p>{message}</p>
                </div>
            );
        }
    }else{
        if(success){
            return (
                <div className={styles.containerSuccess}>
                    <p>{message}</p>
                </div>
            );
        }
        else{
            return (
                <div className={styles.containerFail}>
                    <p>{message}</p>
                </div>
            );
        }
    }

}

export default ResponseMessage;
