import styles from './Index.module.css';
import React from "react";
import PageHeader from '../PageHeader/PageHeader';
import SecondWindow from '../SecondWindow/SecondWindow';
import MainCoverComponent from "../MainCoverComponent/MainCoverComponent";
import { useTranslation } from 'react-i18next';
function Support() {

    const { t } = useTranslation();
    return (
        <div className={styles.main}>
            <MainCoverComponent>
                <PageHeader>{t('App Support')}</PageHeader>

                <SecondWindow>
                    
                </SecondWindow>
            </MainCoverComponent>
        </div>
    );
}

export default Support;