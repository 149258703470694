import axios from 'axios';
import url from "../constants/url";

export default function addCompanyHandler(e, data, setModal, reloadList,setResponseStatus,setSuccess,setMessage,setHidden) {
    e.preventDefault();
    axios({
        method: 'post',
        url: url + "/add_comp",
        headers: {
            'authKey': localStorage.getItem("auth_key")
        },
        data: {
            voen: parseInt(data.voen),
            companyName: data.companyName,
            representative: data.representative,
            email: data.email,
            number: data.number,
            password: data.password,
            type: 2
        }
    })
        .then(function (response) {
            console.log("success");
            console.log(response.data);
            setModal(false);
            reloadList();
            setResponseStatus(true)
        }).catch(function (error) {
            console.log(error)
            console.log("fail")
            setSuccess(false)
            setMessage(error.message)
            setHidden(false)
        });
}