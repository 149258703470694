import React from "react";
import styles from './Index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
const ModalComponentDeleteUser = ({ setModal, deleteUserHandler }) => {
    const { t } = useTranslation();
    return (
        <div className={styles.modalWindow}>
            <div className={styles.modalContainer}>
                <p className={styles.modalTitle}>{t('Delete User')}</p>
                <FontAwesomeIcon onClick={() => setModal(false)} icon={faXmark} className={styles.icon} />
                <div >
                    <form onSubmit={(e) => deleteUserHandler(e)} className={styles.modalInputsContainer}>
                        
                        <button
                            className={styles.button}
                            value="text" >
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            {t('Delete User')} 
                        </button>
                        <Button type={"cancel"}
                            placeholder={t('Cancel')}
                            action={() => setModal(false)}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
export default ModalComponentDeleteUser;