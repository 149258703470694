import styles from './Index.module.css';
import React, {useEffect, useState} from "react";
import PageHeader from '../PageHeader/PageHeader';
import SecondWindow from '../SecondWindow/SecondWindow';
import {MapContainer, TileLayer, Polygon} from "react-leaflet";
import 'leaflet/dist/leaflet.css'
import axios from 'axios';
import url from "../../constants/url";
import MainCoverComponent from "../MainCoverComponent/MainCoverComponent";
import { useTranslation } from 'react-i18next';

const center = [40.391653135698725, 49.873833947024075];


function MapView() {
    const [buildings, setBuildings] = useState([]);
    const { t } = useTranslation();
    useEffect(() => {
        axios({
            method: 'get',
            url: url + "/get_buildings",
            headers: {
                'authKey': localStorage.getItem("auth_key")
            }
        })
            .then(function (response) {
                console.log("success")
                console.log(response.data)
                setBuildings([...response.data]);
            }).catch(function (error) {
                console.log("fail")
                console.log(error)
            });
    }, []
    );

    return (
        <div className={styles.main}>

            <MainCoverComponent>
                <PageHeader>{t('Map')} </PageHeader>
                <SecondWindow>
                    <MapContainer
                        center={center}
                        zoom={13}
                        style={{ width: "100%", height: "100%" }}
                    >
                        <TileLayer
                            url='https://api.maptiler.com/maps/basic-v2/256/{z}/{x}/{y}.png?key=UarKMevRplbybjgy49T8'
                            attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
                        />
                        {
                            buildings.map((item) => {
                                const coordinates = item.coordinates.map((item) => [item[1], item[0]]);
                                console.log(coordinates)
                                return (
                                    <Polygon
                                        pathOptions={{
                                            fillColor: '#FD8D3C',
                                            fillOpacity: 0.7,
                                            weight: 2,
                                            opacity: 1,
                                            dashArray: 3,
                                            color: "white"
                                        }}
                                        positions={coordinates}
                                        eventHandlers={{
                                            mouseover: (e) => {
                                                const layer = e.target;
                                                layer.setStyle({
                                                    fillOpacity: 0.7,
                                                    weight: 5,
                                                    dashArray: "",
                                                    color: '#666',
                                                    fillColor: '#D45962'
                                                })
                                            },
                                            mouseout: (e) => {
                                                const layer = e.target;
                                                layer.setStyle({
                                                    fillOpacity: 5,
                                                    weight: 2,
                                                    dashArray: "3",
                                                    color: 'white',
                                                    fillColor: '#FD8D3C'
                                                })
                                            },
                                            click: (e) => {

                                            },
                                        }}
                                    />
                                )
                            })
                        }
                    </MapContainer>
                </SecondWindow>
            </MainCoverComponent>
        </div>
    );
}

export default MapView;