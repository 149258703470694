import axios from 'axios';
import url from "../constants/url";

export default function addUserHandler(e, data, setModal, reloadList,setResponseStatus,setSuccess,setMessage,setHidden) {
    e.preventDefault();
    let liftIdsTemporary = [];
    for (let i = 0; i < data.liftIds.length; i++) {
        if (data.liftIds[i].status) {
            liftIdsTemporary.push(data.liftIds[i].lift)
        }
    }
    axios({
        method: 'post',
        url: url + "/add_user",
        headers: {
            'authKey': localStorage.getItem("auth_key")
        },
        data: {
            payment: parseInt(data.payment),
            voen: localStorage.getItem("type") === "1" || localStorage.getItem("type") === "0" ? parseInt(data.voen) : parseInt(localStorage.getItem("voen")),
            balance: parseInt(data.balance),
            building: localStorage.getItem("type") === "3"  ? parseInt(localStorage.getItem("building")) : parseInt(data.building),
            floor: parseInt(data.floor),
            garage: data.garage ? 1 : 0,
            blok: parseInt(data.blok),
            days: parseInt(data.days),
            name: data.name,
            surname: data.surname,
            fatherName: data.father,
            fin: data.fin,
            number: data.number,
            apartment: data.apartment,
            liftIds: liftIdsTemporary
        }
    })
        .then(function (response) {
            console.log("success");
            console.log(response.data);
            setModal(false);
            reloadList();
            setResponseStatus(true)
        }).catch(function (error) {
            console.log(error)
            console.log("fail")
            setSuccess(false)
            setMessage(error.message)
            setHidden(false)
        });
}