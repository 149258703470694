import styles from './Index.module.css';
import React, { useState, useEffect } from "react";
import Loading from '../Loading/Loading';
function SecondWindow(props) {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => setLoading(false), 750)
    }, []
    );

    return (
        <div className={styles.secondWindow}>
            {loading ? <Loading size="small" /> : props.children}
        </div>
    );
}
export default SecondWindow;