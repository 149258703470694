import React from "react";
import styles from './Index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
const ModalComponentEditCompany = ({ setModal, editCompanyHandler,deleteCompanyHandler, data, setData }) => {
    const { t } = useTranslation();
    return (
        <div className={styles.modalWindow}>
            <div className={styles.modalContainer}>
                <p className={styles.modalTitle}>{t('Edit Company')}</p>
                <FontAwesomeIcon onClick={() => setModal(false)} icon={faXmark} className={styles.icon} />
                <form onSubmit={(e) => editCompanyHandler(e)} className={styles.modalInputsContainer}>
                    <input
                        className={styles.modalInput}
                        value={data.voen}
                        placeholder={t('VOEN')}
                        type='text'
                        onChange={e => setData({ ...data, voen: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.companyName}
                        placeholder={t('Company Name')}
                        type='text'
                        onChange={e => setData({ ...data, companyName: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.representative}
                        placeholder={t('Legal Representative')}
                        type='text'
                        onChange={e => setData({ ...data, representative: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.number}
                        placeholder={t('Number')}
                        type='text'
                        onChange={e => setData({ ...data, number: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.email}
                        placeholder={t('E-mail')}
                        type='text'
                        onChange={e => setData({ ...data, email: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.password}
                        placeholder={t('Password')}
                        type='text'
                        onChange={e => setData({ ...data, password: e.target.value })}
                    />
                    <button
                        className={styles.button}
                        value="text" >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        {t('Save')}
                    </button>

                </form>
                <Button type={"cancel"}
                        placeholder={t('Delete Company')}
                        action={() => {
                            deleteCompanyHandler()
                            setModal(false)
                        }}
                    />
            </div>
        </div>
    );
};
export default ModalComponentEditCompany;