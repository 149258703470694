import React from "react";
import styles from './Index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
const ModalComponentEditBlock = ({ setModal, editBlockHandler, deleteBlockHandler, data, setData }) => {
    const { t } = useTranslation();
    return (
        <div className={styles.modalWindow}>
            <div className={styles.modalContainer}>
                <p className={styles.modalTitle}>{t('Edit Entrance')}</p>
                <FontAwesomeIcon onClick={() => setModal(false)} icon={faXmark} className={styles.icon} />
                <form onSubmit={(e) => editBlockHandler(e)} className={styles.modalInputsContainer}>
                    <input
                        className={styles.modalInput}
                        value={data.building}
                        placeholder={t('Building Id')}
                        type='text'
                        onChange={e => setData({ ...data, building: e.target.value })}
                    />

                    <input
                        className={styles.modalInput}
                        value={data.name}
                        placeholder={t('Entrance Name')}
                        type='text'
                        onChange={e => setData({ ...data, name: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.mac}
                        placeholder={t('MAC Address')}
                        type='text'
                        onChange={e => setData({ ...data, mac: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.floor}
                        placeholder={t('Floor')}
                        type='text'
                        onChange={e => setData({ ...data, floor: e.target.value })}
                    />
                    <button
                        className={styles.button}
                        value="text" >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        {t('Edit Entrance')}
                    </button>

                </form>
                <Button type={"cancel"}
                    placeholder= {t('Delete Entrance')}
                    action={() => {
                        deleteBlockHandler()
                        setModal(false)
                    }}
                />
            </div>
        </div>
    );
};
export default ModalComponentEditBlock;