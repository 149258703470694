import axios from 'axios';
import url from "../constants/url";

export default function addCardHandler(e, data,setModal,reloadList) {
    e.preventDefault();
    console.log("submitted")
    console.log(data)
    e.preventDefault();
    axios({
        method: 'post',
        url: url + "/add_card",
        headers: {
            'authKey': localStorage.getItem("auth_key")
        },
        data: {
            card: data.card,
            user: data.user,
            fullname: data.fullname,
            isGlobalUsed: data.isGlobalUsed
        }
    })
        .then(function (response) {
            console.log("success");
            console.log(response.data);
            setModal(false);
            reloadList();
        }).catch(function (error) {
            console.log(error)
            console.log("fail")
        });
}