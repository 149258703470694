import axios from 'axios';
import url from "../constants/url";

export default function editUserHandler(e,data, loadedData, setModal, reloadList,setSuccess,setResponseStatus,setMessage,setHidden) {
    e.preventDefault();
    console.log("Submitted");
    console.log(data);

    axios({
        method: 'put',
        url: url + "/upd_users",
        headers: {
            'authKey': localStorage.getItem("auth_key"),
            'user': parseInt(data.user)
        },
        data: {
            payment: parseInt(loadedData.payment),
            balance: parseInt(loadedData.balance),
            floor: parseInt(loadedData.floor),
            garage: loadedData.garage ? 1 : 0,
            days: parseInt(loadedData.days),
            name: loadedData.name,
            surname: loadedData.surname,
            fatherName: loadedData.fatherName,
            fin: loadedData.fin,
            number: loadedData.number,
            apartment: loadedData.apartment,
        }
    })
        .then(function (response) {
            console.log("success");
            console.log(response.data);
            setModal(false);
            reloadList();
            setResponseStatus(true)
        }).catch(function (error) {
            console.log(error)
            console.log("fail")
            setSuccess(false)
            setMessage(error.message)
            setHidden(false)
        });
}