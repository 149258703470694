import styles from './Index.module.css';
import React, { useState, useEffect } from "react";
import ModalWindow from '../ModalWindow/ModalWindow';
import PageHeader from '../PageHeader/PageHeader';
import SecondWindow from '../SecondWindow/SecondWindow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faElevator, faFolder } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import url from "../../constants/url";
import MainCoverComponent from "../MainCoverComponent/MainCoverComponent";
import SearchInput from "../SearchInput/SearchInput";
import { useTranslation } from 'react-i18next';
function ListElevators() {
    const [search, setSearch] = useState('');
    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalData, setModalData] = useState(null);
    const [list, setList] = useState([]);
    const { t } = useTranslation();
    function reloadList() {


        if (localStorage.getItem("type") === "3") {
            axios({
                method: 'get',
                url: url + "/get_lifts_by_building",
                headers: {
                    'authKey': localStorage.getItem("auth_key"),
                    'building': localStorage.getItem("building")
                }
            })
                .then(function (response) {
                    console.log("success")
                    console.log(response.data)
                    setList([...response.data]);
                }).catch(function (error) {
                    console.log("fail")
                    console.log(error)
                });
        } else if (localStorage.getItem("type") === "2") {
            axios({
                method: 'get',
                url: url + "/get_lifts_by_voen",
                headers: {
                    'authKey': localStorage.getItem("auth_key"),
                    'voen': localStorage.getItem("voen")
                }
            })
                .then(function (response) {
                    console.log("success")
                    console.log(response.data)
                    setList([...response.data]);
                }).catch(function (error) {
                    console.log("fail")
                    console.log(error)
                });
        } else {
            axios({
                method: 'get',
                url: url + "/get_lifts",
                headers: {
                    'authKey': localStorage.getItem("auth_key")
                }
            })
                .then(function (response) {
                    console.log("success")
                    console.log(response.data)
                    setList([...response.data]);
                }).catch(function (error) {
                    console.log("fail")
                    console.log(error)
                });
        }

    }
    useEffect(() => {
        reloadList();
    }, []
    );

    return (
        <div className={styles.main}>
            <MainCoverComponent>
                <PageHeader>{t('Elevators')}</PageHeader>

                <SecondWindow>
                    <div className={styles.firstRaw}>


                        <SearchInput
                            value={search}
                            setValue={setSearch}
                        />
                    </div>

                    <div className={styles.cardsContainer}>
                        {
                            list.map((item, index) =>
                                <div
                                    key={index}
                                    onClick={() => { }}
                                    className={styles.cardContainer}
                                >
                                    <FontAwesomeIcon icon={faElevator} className={styles.icon} />
                                    <p className={styles.title}>{item.liftName}</p>
                                </div>
                            )
                        }
                    </div>

                </SecondWindow>
            </MainCoverComponent>
        </div>
    );
}

export default ListElevators;