import axios from 'axios';
import url from "../constants/url";

export default function addBuildingHandler(e, data, setModal, reloadList,setResponseStatus,setSuccess,setMessage,setHidden) {
    e.preventDefault();
    console.log("data");
    console.log(data)
    axios({
        method: 'post',
        url: url + "/add_building",
        headers: {
            'authKey': localStorage.getItem("auth_key")
        },
        data: {
            voen: parseInt(data.voen),
            name: data.buildingName,
            coordinates: data.coordinates,
        }
    })
        .then(function (response) {
            console.log("success");
            console.log(response.data);
            setModal(false);
            reloadList();
            setResponseStatus(true)
        }).catch(function (error) {
            console.log(error)
            console.log("fail")
            setSuccess(false)
            setMessage(error.message)
            setHidden(false)
            setTimeout(()=>setHidden(true), 2000)
        });
}