import React, { useState,useEffect } from "react";
import styles from './Index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
const ModalComponentEditBuilding = ({ setModal, editBuildingHandler,deleteBuildingHandler, data, setData, companies }) => {
    const { t } = useTranslation();
    const [coordinates, setCoordinates] = useState([
        "",
        "",
        "",
        ""
    ]);
    // const [inputs, setInputs] = useState([]);

    useEffect(() => {
        if(data.coordinates.length > 0){
            let coordinatesTemp = [];
            for(let i = 0; i< data.coordinates.length; i++){
                coordinatesTemp.push(data.coordinates[i][1] + " , " + data.coordinates[i][0])
            }
            data.coordinates = [...coordinatesTemp];
        }else{
            data.coordinates = [...coordinates];
        }

    }, []
    );

    const handleCoordinate = (e, index) => {
        let coordinatesTemp = [...data.coordinates];
        coordinatesTemp[index] = e.target.value;
        setCoordinates(coordinatesTemp);
        setData({ ...data, coordinates: [...coordinatesTemp] })
    };
    const handlePlus = () => {
        let coordinatesTemp = [...data.coordinates];
        coordinatesTemp.push("");
        setCoordinates(coordinatesTemp);
        setData({ ...data, coordinates: [...coordinatesTemp] })
    };

    return (
        <div className={styles.modalWindow}>
            <div className={styles.modalContainer}>
                <p className={styles.modalTitle}>{t('Edit Building')}</p>
                <FontAwesomeIcon onClick={() => setModal(false)} icon={faXmark} className={styles.icon} />
                <form onSubmit={(e) => editBuildingHandler(e)} className={styles.modalInputsContainer}>

                    <div className={styles.selectWrapper}>
                        <select onChange={e => setData({ ...data, voen: e.target.value })} className={styles.select}>
                            {companies.map(item =>
                                <option selected={item.voen === data.voen ? "selected" : ""} value={item.voen}>
                                    {item.companyName}
                                </option>
                            )}
                        </select>
                    </div>


                    <input
                        className={styles.modalInput}
                        value={data.name}
                        placeholder={t('Building Name')}
                        type='text'
                        onChange={e => setData({ ...data, name: e.target.value })}
                    />

                    <p className={styles.label}>{t('Coordinates')}:</p>
                    <div className={styles.coordinatesContainer}>
                        {data.coordinates.map((item, index) => {

                            if (index === data.coordinates.length - 1) {
                                return (
                                    <div className={styles.lastContainer}>
                                        <p className={styles.index}>{index + 1}</p>
                                        <input
                                            className={styles.coordinateInput}
                                            value={item}
                                            placeholder={t('Coordinates')}
                                            type='text'
                                            onChange={(e) => handleCoordinate(e, index)}
                                        />
                                        <div onClick={handlePlus} className={styles.AddButton}>
                                            <FontAwesomeIcon icon={faXmark} className={styles.iconAdd} />
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className={styles.lastContainer}>
                                        <p className={styles.index}>{index + 1}</p>
                                        <input
                                            className={styles.coordinateInput}
                                            value={item}
                                            placeholder={t('Coordinates')}
                                            type='text'
                                            onChange={(e) => handleCoordinate(e, index)}
                                        />
                                    </div>
                                )

                            }
                        })}


                    </div>

                    <button
                        className={styles.button}
                        value="text" >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        {t('Edit Building')}
                    </button>

                    
                </form>
                <Button type={"cancel"}
                        placeholder={t('Delete Building')}
                        action={() => {
                            deleteBuildingHandler()
                            setModal(false)
                        }}
                    />
            </div>
        </div>
    );
};
export default ModalComponentEditBuilding;