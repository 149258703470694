import React, { useState } from "react";
import styles from './Index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
const ModalComponentAddBuilding = ({ setModal, addBuildingHandler, data, setData, companies }) => {
    const { t } = useTranslation();
    const [coordinates, setCoordinates] = useState([
        "",
        "",
        "",
        ""
    ]);
    // const [inputs, setInputs] = useState([]);

    const handleCoordinate = (e, index) => {
        let coordinatesTemp = [...coordinates];
        coordinatesTemp[index] = e.target.value;
        setCoordinates(coordinatesTemp);
        setData({ ...data, coordinates: [...coordinatesTemp] })
    };
    const handlePlus = () => {
        let coordinatesTemp = [...coordinates];
        coordinatesTemp.push("");
        setCoordinates(coordinatesTemp);
        setData({ ...data, coordinates: [...coordinatesTemp] })
    };

    return (
        <div className={styles.modalWindow}>
            <div className={styles.modalContainer}>
                <p className={styles.modalTitle}>Add Building</p>
                <FontAwesomeIcon onClick={() => setModal(false)} icon={faXmark} className={styles.icon} />
                <form onSubmit={(e)=>addBuildingHandler(e)} className={styles.modalInputsContainer}>

                    <div className={styles.selectWrapper}>
                        <select onChange={e => setData({ ...data, voen: e.target.value })} className={styles.select}>
                            <option value={null}>{t('Company')}</option>
                            {companies.map(item => <option key={item.voen} value={item.voen}>{item.companyName}</option>)}
                        </select>
                    </div>
                    <input
                        className={styles.modalInput}
                        value={data.companyName}
                        placeholder={t('Building Name')}
                        type='text'
                        onChange={e => setData({ ...data, buildingName: e.target.value })}
                    />
                    <p className={styles.label}>{t('Coordinates')}:</p>
                    <div className={styles.coordinatesContainer}>
                        {coordinates.map((item, index) => {

                            if (index === coordinates.length - 1) {
                                return (
                                    <div key={index} className={styles.lastContainer}>
                                        <p  className={styles.index}>{index + 1}</p>
                                        <input
                                            className={styles.coordinateInput}
                                            value={item}
                                            placeholder={t('Coordinates')}
                                            type='text'
                                            onChange={(e) => handleCoordinate(e, index)}
                                        />
                                        <div onClick={handlePlus} className={styles.AddButton}>
                                            <FontAwesomeIcon icon={faXmark} className={styles.iconAdd} />
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={index}  className={styles.lastContainer}>
                                        <p  className={styles.index}>{index + 1}</p>
                                        <input
                                            className={styles.coordinateInput}
                                            value={item}
                                            placeholder={t('Coordinates')}
                                            type='text'
                                            onChange={(e) => handleCoordinate(e, index)}
                                        />
                                    </div>
                                )

                            }
                        })}


                    </div>
                    <button
                        className={styles.button}
                        value="text" >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        {t('Add Building')}
                    </button>
                    <Button type={"cancel"}
                        placeholder={t('Cancel')}
                        action={() => setModal(false)}
                    />
                </form>
            </div>
        </div>
    );
};
export default ModalComponentAddBuilding;