import React from "react";
import styles from './Index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button/Button';
import Switch from '../Switch/Switch';
import { useTranslation } from 'react-i18next';
const ModalComponentEditCard = ({ setModal, editCardHandler, data, setData }) => {
    const { t } = useTranslation();
    return (
        <div className={styles.modalWindow}>
            <div className={styles.modalContainer}>
                <p className={styles.modalTitle}>{t('Edit Card')}</p>
                <FontAwesomeIcon onClick={() => setModal(false)} icon={faXmark} className={styles.icon} />
                <div >
                    <form onSubmit={(e) => editCardHandler(e)} className={styles.modalInputsContainer}>
                        <input
                            className={styles.modalInput}
                            value={data.fullname}
                            placeholder={t('Full Name')}
                            type='text'
                            onChange={e => setData({ ...data, fullname: e.target.value })}
                        />
                        <input
                            className={styles.modalInput}
                            value={data.card}
                            placeholder={t('Card Id')}
                            type='text'
                            onChange={e => setData({ ...data, card: e.target.value })}
                        />

                        <div className={styles.switchContainer}>
                            <p className={styles.switchLabel}>VIP</p>
                            <Switch value={data.isGlobalUsed} onClick={swicthValue => setData({ ...data, isGlobalUsed: swicthValue })} />
                        </div>
                        
                        {console.log(data)}

                        <button
                            className={styles.button}
                            value="text" >
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            {t('Save')}
                        </button>
                        <Button type={"cancel"}
                            placeholder={t('Cancel')}
                            action={() => setModal(false)}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
export default ModalComponentEditCard;