import React, { useEffect, useState ,useCallback} from "react";
import styles from './Index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import url from "../../constants/url";
import { useTranslation } from 'react-i18next';
const ModalComponentUserPayments = ({ setModal, data, reloadList }) => {
    const { t } = useTranslation();
    const [list, setList] = useState([])
    const [payment, setPayment] = useState("")
    const reloadListLocal = useCallback(() => {
        axios({
            method: 'get',
            url: url + "/get_payments_by_userId",
            headers: {
                'authKey': localStorage.getItem("auth_key"),
                'user': data.user
            }
        })
            .then(function (response) {
                setList([...response.data]);
            })
            .catch(function (error) {
                console.error("Failed to load payments:", error);
            });
    }, [data.user]);

    useEffect(() => {
        reloadListLocal();
    }, [reloadListLocal]);

    function addPaymentHandler() {
        axios({
            method: 'post',
            url: url + "/add_payment",
            headers: {
                'authKey': localStorage.getItem("auth_key")
            },
            data: {
                user: parseInt(data.user),
                amount:  parseInt(payment)
            }
        })
        .then(function (response) {
            // console.log("success")
            reloadListLocal();

        }).catch(function (error) {
            console.log("fail")

            console.log(error)
        });
    }
    function addDebtHandler() {
        axios({
            method: 'post',
            url: url + "/add_debt",
            headers: {
                'authKey': localStorage.getItem("auth_key")
            },
            data: {
                user: parseInt(data.user),
                amount:  parseInt(payment)
            }
        })
            .then(function (response) {
                // console.log("success")
                reloadListLocal();

            }).catch(function (error) {
                console.log("fail")

                console.log(error)
            });
    }

    return (
        <div className={styles.modalWindow}>
            <div className={styles.modalContainer}>
                <div className={styles.modalHeadContainer}>
                    <p className={styles.modalTitle}>{t('Payments')}</p>
                    <div className={styles.inputsContainer}>
                        <div className={styles.textInputContainer}>
                            <input
                                className={styles.modalInput}
                                value={payment}
                                placeholder={t('Payment')}
                                type='text'
                                onChange={e => {setPayment(e.target.value)}}
                            />
                        </div>
                        <div onClick={addPaymentHandler} className={styles.smallButtonGreen}>{t('Add Payment')}</div>
                        <div onClick={addDebtHandler} className={styles.smallButtonRed}>{t('Add Debt')}</div>
                    </div>
                    <FontAwesomeIcon onClick={() =>{ 
                        reloadList();
                        setModal(false)
                        
                        }} icon={faXmark} className={styles.icon} />
                </div>
                <div className={styles.tableContainer}>
                    <div className={styles.tableHeadContainer}>
                        <p className={styles.tableHeadNo}>No̱</p>
                        <p className={styles.tableHeadDate}>{t('Date')}</p>
                        <p className={styles.tableHeadTime}>{t('Time')}</p>
                        <p className={styles.tableHeadAmount}>{t('Amount')}</p>
                        <p className={styles.tableHeadBalance}>{t('Balance')}</p>
                        <p className={styles.tableHeadSource}>{t('Source')}</p>
                        <p className={styles.tableHeadId}>{t('Transaction Id')}</p>
                    </div>
                    <div className={styles.tableContentContainer}>
                        {list.map((item, index) => {
                            return (<div key={item.txnId} className={styles.tableDataContainer}>
                                <p className={styles.tableHeadNo}>{index + 1}</p>
                                <p className={styles.tableHeadDate}>{item.date}</p>
                                <p className={styles.tableHeadTime}>{item.time}</p>
                                <p className={styles.tableHeadAmount}>{item.amount}</p>
                                <p className={styles.tableHeadBalance}>{item.balance}</p>
                                <p className={styles.tableHeadSource}>{item.source}</p>
                                <p className={styles.tableHeadId}>{item.txnId}</p>
                            </div>)

                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ModalComponentUserPayments;