import styles from './Index.module.css';
import React from "react";

function Button({placeholder, type, action}) {

    return (
        <button onClick={()=>action()}
         className={
            type === "confirm" ?styles.button:
            type === "cancel" ? styles.cancelButton:
            ""
        } 
         value="text" >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            {placeholder}
        </button>
    )

}

export default Button;