import { useEffect, useState } from "react";
import Login from "./pages/LoginPage/LoginPage";
import MasterAdmin from "./pages/MasterAdminPage/MasterAdminPage";
import axios from 'axios';
import url from "./constants/url";
import './App.css';
import Loading from "./components/Loading/Loading";
import ResponseMessage from "./components/ResponseMessage/ResponseMessage";
import useViewportHeight from "./hooks/useViewportHeight";
import './i18n'; //language pack
import { useTranslation } from 'react-i18next';
function App() {

  useViewportHeight();

  const { t } = useTranslation();
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Wrong Password");
  const [success, setSuccess] = useState(false);
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("auth_key")) {
      var loadTime;
      loadTime = new Date();
      var timeDiff = loadTime.getTime() - localStorage.getItem("loginTime");
      timeDiff /= 1000
      if (timeDiff < 3600) {
        setAuth(true);
      }
    }
  }, []);

  const handleSubmit = (e, username, password) => {
    setLoading(true)
    if(e){
      e.preventDefault();
    }

    console.log(password)
    axios({
      method: 'post',
      url: url + "/login",
      data: {
        username: username,
        password: password
      }
    })
      .then(function (response) {
        console.log("logged in")
        console.log(response.data)
        localStorage.setItem("type", response.data.type)
        localStorage.setItem("auth_key", response.data.auth_key)
        if (response.data.type === 1) {
          localStorage.setItem("name", response.data.name)
          localStorage.setItem("surname", response.data.surname)
        } else if (response.data.type === 2) {
          localStorage.setItem("companyName", response.data.companyName)
          localStorage.setItem("voen", response.data.voen)
        } else if (response.data.type === 3) {
          localStorage.setItem("name", response.data.name)
          localStorage.setItem("building", response.data.building)
          localStorage.setItem("voen", response.data.voen)
        } else {
          localStorage.setItem("name", response.data.name)
          localStorage.setItem("surname", response.data.surname)
        }
        var loginTime = new Date();
        loginTime = loginTime.getTime();

        localStorage.setItem("loginTime", loginTime)
        setLoading(false)
        // localStorage.getItem("auth_key")
        setAuth(true);

      }).catch(function (error) {
        console.log(error)
        console.log("fail")
        setLoading(false)
        setSuccess(false)
        setMessage(error.message)
        setHidden(false)
        setTimeout(() => setHidden(true), 2000)
      });
  };

  function logOut() {
    localStorage.setItem("name", "")
    localStorage.setItem("surname", "")
    localStorage.setItem("type", "")
    localStorage.setItem("auth_key", "")
    localStorage.setItem("companyName", "")
    localStorage.setItem("loginTime", "")
    localStorage.setItem("building", "")
    setAuth(false)
  }

  if (auth) {
    return (
      <div className="App">
        {loading ? <Loading size="big" /> : null}
        <MasterAdmin logOut={() => logOut()} />
        <p className="nano"><span className="blur">{t('Powered by')}</span> Portflix</p>
        <ResponseMessage hidden={hidden} success={true} message={message} />
      </div>
    );
  }
  else {
    return (
      <div className="App">
        {loading ? <Loading size="big" /> : null}
        <Login handleSubmit={handleSubmit} setAuth={setAuth} />
        <p className="nano"><span className="blur">{t('Powered by')}</span> Portflix</p>
        <ResponseMessage hidden={hidden} success={success} message={message} />
      </div>
    );
  }



}

export default App;
