import axios from 'axios';
import url from "../constants/url";

export default function recoverBlokHandler(e, data, setModal,reloadList) {
    e.preventDefault();
    console.log("submitted")
    console.log(data)
    e.preventDefault();
    axios({
        method: 'put',
        url: url + "/recover_blok",
        headers: {
            'authKey': localStorage.getItem("auth_key"),
            'blok': data.blok
        }
    })
        .then(function (response) {
            console.log("success");
            console.log(response.data);
            setModal(false);
            reloadList();
        }).catch(function (error) {
            console.log(error)
            console.log("fail")
        });
}