import styles from './Index.module.css';
import React from "react";
import { useTranslation } from 'react-i18next';
function AddButton({type, setModalType, setModalData , setModal}) {
    const { t } = useTranslation();
    return (
        <div onClick={() => {
            setModalType(type)
            setModalData(null)
            setModal(true)
        }} className={styles.button}>
            {t('Add')}
        </div>
    );
}

export default AddButton;