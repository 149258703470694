import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Index.module.css';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div className={styles.selectWrapper}>
            <select onChange={(e) => changeLanguage(e.target.value)} className={styles.select} defaultValue={i18n.language}>
                <option value="en">En</option>
                <option value="az">Az</option>
                <option value="ru">Ru</option>
            </select>
        </div>
    );
};

export default LanguageSwitcher;
