import React from "react";
import styles from './Index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button/Button';
import Switch from '../Switch/Switch';
import { useTranslation } from 'react-i18next';
const ModalComponentEditElevator = ({
    setModal,
    editLiftHandler,
    deleteLiftHandler,
    data,
    setData
}) => {
    const { t } = useTranslation();
    return (
 
        <div className={styles.modalWindow}>
            <div className={styles.modalContainer}>
                <p className={styles.modalTitle}>{t('Edit Elevator')}</p>
                <FontAwesomeIcon onClick={() => setModal(false)} icon={faXmark} className={styles.icon} />
                <form onSubmit={(e) => editLiftHandler(e)} className={styles.modalInputsContainer}>
                    <input
                        className={styles.modalInput}
                        value={data.liftName}
                        placeholder={t('Elevator Name')}
                        type='text'
                        onChange={e => setData({ ...data, liftName: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.floor}
                        placeholder={t('Floor')}
                        type='text'
                        onChange={e => setData({ ...data, floor: e.target.value })}
                    />

                    <input
                        className={styles.modalInput}
                        value={data.mac}
                        placeholder={t('MAC Address')}
                        type='text'
                        onChange={e => setData({ ...data, mac: e.target.value })}
                    />
                     {console.log("data")}
                    {console.log(data)}
                    <div className={styles.switchContainer}>
                        <p className={styles.switchLabel}>{t('Garage')}</p>
                        <Switch value={data.garage} onClick={swicthValue => setData({ ...data, garage: swicthValue })} />
                    </div>
                    <div className={styles.switchContainer}>
                        <p className={styles.switchLabel}>{t('Camera')}</p>
                        <Switch value={data.isCamera} onClick={swicthValue => setData({ ...data, isCamera: swicthValue })} />
                    </div>
                    {
                        data.isCamera ?
                            <input
                                className={styles.modalInput}
                                value={data.camIp}
                                placeholder={t('Camera Ip')}
                                type='text'
                                onChange={e => setData({ ...data, camIp: e.target.value })}
                            />
                            :
                            null
                    }
                    {
                        data.isCamera ?
                            <input
                                className={styles.modalInput}
                                value={data.camPassword}
                                placeholder={t('Camera Password')}
                                type='text'
                                onChange={e => setData({ ...data, camPassword: e.target.value })}
                            />
                            :
                            null
                    }
                    <button
                        className={styles.button}
                        value="text" >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        {t('Save Elevator')}
                    </button>


                </form>
                
                    <Button type={"cancel"}
                        placeholder={t('Delete Elevator')}
                        action={() => {
                            deleteLiftHandler()
                            setModal(false)
                        }}
                    />
            </div>
        </div>
    );
};
export default ModalComponentEditElevator;