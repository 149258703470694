import axios from 'axios';
import url from "../constants/url";

export default function deleteLiftHandler( modalDataOld,setModal, reloadList,setResponseStatus,setSuccess,setMessage,setHidden) {
    console.log("here")
    axios({
        method: 'delete',
        url: url + "/del_lift",
        headers: {
            'authKey': localStorage.getItem("auth_key"),
            'lift': modalDataOld.lift
        }
    })
        .then(function (response) {
            console.log("success")
            console.log(response.data);
            setModal(false);
            reloadList();
            setResponseStatus(true)
        }).catch(function (error) {
            console.log(error)
            console.log("fail")
            setSuccess(false)
            setMessage(error.message)
            setHidden(false)
        });
}