import styles from './Index.module.css';
import React, { useState, useEffect } from "react";
import ModalWindow from '../ModalWindow/ModalWindow';
import PageHeader from '../PageHeader/PageHeader';
import SecondWindow from '../SecondWindow/SecondWindow';
import axios from 'axios';
import url from "../../constants/url";
import ResponseMessage from "../ResponseMessage/ResponseMessage";
import MainCoverComponent from "../MainCoverComponent/MainCoverComponent";
import AddButton from '../AddButton/AddButton';
import SearchInput from "../SearchInput/SearchInput";
import { useTranslation } from 'react-i18next';
function EditBuilding() {
    const [search, setSearch] = useState('');
    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalData, setModalData] = useState(null);
    const [list, setList] = useState([]);
    const { t } = useTranslation();
    const [responseStatus, setResponseStatus] = useState(false);


    function reloadList() {
        axios({
            method: 'get',
            url: url + "/get_buildings",
            headers: {
                'authKey': localStorage.getItem("auth_key")
            }
        })
            .then(function (response) {
                console.log("success")
                console.log(response.data)
                setList([...response.data]);
            }).catch(function (error) {
                console.log("fail")
                console.log(error)
            });
    }

    useEffect(() => {
        reloadList();
    }, []
    );


    return (
        <div className={styles.main}>

            {modal ? <ModalWindow
                modalType={modalType}
                modalData={{ ...modalData }}
                setModal={setModal}
                reloadList={() => reloadList()}
                setResponseStatus={() => setResponseStatus(true)}

            /> : null}


            <MainCoverComponent>
                <PageHeader>{t('Edit Buildings')}</PageHeader>

                <SecondWindow>
                    <div className={styles.firstRaw}>
                        <AddButton
                            type={"addBuilding"}
                            setModalType={setModalType}
                            setModalData={setModalData}
                            setModal={setModal}
                        />

                        <SearchInput
                            value={search}
                            setValue={setSearch}
                        />
                    </div>


                    <div className={styles.dataListTitlesContainer}>
                        <div className={styles.singleTitleContainer}>
                            <p className={styles.title}>{t('Building Id')}</p>
                        </div>
                        <div className={styles.singleTitleContainer}>
                            <p className={styles.title}>{t('Building Name')}</p>
                        </div>
                        <div className={styles.singleTitleContainer}>
                            <p className={styles.title}>{t('VOEN')}</p>
                        </div>
                        <div className={styles.singleTitleContainer}>
                            <p className={styles.title}>{t('User Count')}</p>
                        </div>
                        <div className={styles.singleTitleContainer}>
                            <p className={styles.title}>{t('Password')}</p>
                        </div>
                    </div>



                    {
                        list.map(item =>
                            <div
                                key={item.building}
                                onClick={() => {
                                    setModalType("editBuilding")
                                    setModalData({ ...item })
                                    setModal(true)
                                }}
                                className={styles.tableRow}
                            >

                                <div className={styles.singleTitleContainer}>
                                    <p className={styles.title}>{item.building}</p>
                                </div>
                                <div className={styles.singleTitleContainer}>
                                    <p className={styles.title}>{item.name}</p>
                                </div>
                                <div className={styles.singleTitleContainer}>
                                    <p className={styles.title}>{item.voen}</p>
                                </div>
                                <div className={styles.singleTitleContainer}>
                                    <p className={styles.title}>{item.userCount}</p>
                                </div>
                                <div className={styles.singleTitleContainer}>
                                    <p className={styles.title}>{item.password}</p>
                                </div>
                            </div>
                        )
                    }
                </SecondWindow>
            </MainCoverComponent>
            <ResponseMessage hidden={!responseStatus} success={"success"} message={modalType === "addBuilding" ? "Building Added Successfully" : "Building Saved Successfully"} />

        </div>
    );
}

export default EditBuilding;