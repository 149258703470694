import React from "react";
const Switch = ({ value, onClick, }) => {
    return (
        <div
            style={{
                width: "50px",
                height: "30px",
                borderRadius: 30,
                border: "1px #ddd solid",
                position: "relative",
                transition: "0.4s",
                cursor: "pointer",
                background: value ? "#2bb8e4" : "aliceblue",
            }}
            onClick={() => {
                onClick(!value);
            }}
        >
            <div
                style={{
                    background: value ? "white" : "#2bb8e4",
                    borderRadius: "100%",
                    height: "25px",
                    width: "25px",
                    position: "absolute",
                    top: 2,
                    left: value
                        ? 22
                        : 3,
                    transition: "0.4s",
                }}
            ></div>
            <input
                type="checkbox"
                value={value}
                onChange={(e) => {
                    onClick(e.target.value);
                }}
                style={{ display: "none" }}
            />
        </div >
    );
};
export default Switch;