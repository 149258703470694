import React from "react";
import styles from './Index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button/Button';
const ModalComponentRecover = ({ setModal, recover, data }) => {
    return (
        <div className={styles.modalWindow}>
            <div className={styles.modalContainer}>
                <p className={styles.modalTitle}>Recover Item?</p>
                <FontAwesomeIcon onClick={() => setModal(false)} icon={faXmark} className={styles.icon} />
                <div >
                    <form onSubmit={(e) => recover(e)} className={styles.modalInputsContainer}>
                        
                        <button
                            className={styles.button}
                            value="text" >
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            Recover Item
                        </button>
                        <Button type={"cancel"}
                            placeholder={"Cancel"}
                            action={() => setModal(false)}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
export default ModalComponentRecover;