import styles from './Index.module.css';
import React, { useState, useEffect } from "react";
import PageHeader from '../PageHeader/PageHeader';
import SecondWindow from '../SecondWindow/SecondWindow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding} from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import url from "../../constants/url";
import MainCoverComponent from "../MainCoverComponent/MainCoverComponent";
import SearchInput from "../SearchInput/SearchInput";
import { useTranslation } from 'react-i18next';
function ListBuildings() {
    const [search, setSearch] = useState('');
    const [list, setList] = useState([]);
    const [data, setData] = useState([]);

    const { t } = useTranslation();

    function reloadList() {
        console.log("localStorage.getItem(type)")
        console.log(localStorage.getItem("type"))
        if(localStorage.getItem("type")==="2"){
            axios({
                method: 'get',
                url: url + "/get_buildings_by_voen",
                headers: {
                    'authKey': localStorage.getItem("auth_key"),
                    'voen': localStorage.getItem("voen")
                }
            })
                .then(function (response) {
                    console.log("success")

                    console.log(response.data)
                    setList([...response.data]);
                }).catch(function (error) {
                    console.log("fail")
                    console.log(error)
                });
        }else{
            axios({
                method: 'get',
                url: url + "/get_buildings",
                headers: {
                    'authKey': localStorage.getItem("auth_key")
                }
            })
                .then(function (response) {
                    console.log("success")
                    console.log(response.data)
                    setList([...response.data]);
                }).catch(function (error) {
                    console.log("fail")
                    console.log(error)
                });
        }

    }

    function filterListHandler(value) {
        if(value==="noFilter"){
            axios({

                method: 'get',
                url: url + "/get_buildings",
                headers: {
                    'authKey': localStorage.getItem("auth_key"),
                }
            })
                .then(function (response) {
                    console.log("success")
                    console.log(response.data)
                    setList([...response.data]);
                }).catch(function (error) {
                    console.log("fail")
                    console.log(error)
                });
        }else{
            axios({

                method: 'get',
                url: url + "/get_buildings_by_voen",
                headers: {
                    'authKey': localStorage.getItem("auth_key"),
                    'voen': value 
                }
            })
                .then(function (response) {
                    console.log("success")
                    console.log(response.data)
                    setList([...response.data]);
                }).catch(function (error) {
                    console.log("fail")
                    console.log(error)
                    console.log(error.response.status)
                    if(error.response.status === 404){
                        setList([]);
                    }
                });
        }
        
    }

    useEffect(() => {
        reloadList();
    }, []);


    return (
        <div className={styles.main}>
            <MainCoverComponent>
                <PageHeader>{t('Buildings')}</PageHeader>

                <SecondWindow>
                    <div className={styles.firstRaw}>
                        <div className={styles.selectWrapper}>
                            <select placeholder="Select Company" onChange={e => filterListHandler(e.target.value)} className={styles.select}>
                            <option value={"noFilter"}>Company</option>
                                {data.map(item => <option value={item.voen}>{item.companyName}</option>)}
                            </select>
                        </div>

                        <SearchInput
                            value={search}
                            setValue={setSearch}
                        />
                    </div>

                    <div className={styles.cardsContainer}>
                        {
                            list.map((item, index) =>
                                <div
                                    key={item.building}
                                    onClick={() => { }}
                                    className={styles.cardContainer}
                                >
                                    <FontAwesomeIcon icon={faBuilding} className={styles.icon} />
                                    <p className={styles.title}>{item.name}</p>
                                </div>
                            )
                        }
                    </div>

                </SecondWindow>
            </MainCoverComponent>
        </div>
    );
}

export default ListBuildings;