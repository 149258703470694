import React  from "react";
import styles from './Index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button/Button';
import Switch from '../Switch/Switch';
import { useTranslation } from 'react-i18next';
// import cardImage from '../../assets/cardImage.jpeg';
// import tagImage from "../../assets/tag.png";
// import appleImage from "../../assets/apple.png";
// import androidImage from "../../assets/android.png";

const ModalComponentAddCard = ({ setModal, addCardHandler, data, setData }) => {
    const { t } = useTranslation();
    // const [active,setActive] = useState(0);
    return (
        <div className={styles.modalWindow}>
            <div className={styles.modalContainer}>
                <p className={styles.modalTitle}>{t('Add')}</p>
                <FontAwesomeIcon onClick={() => setModal(false)} icon={faXmark} className={styles.icon} />
                <div >
                    <form onSubmit={(e) => addCardHandler(e)} className={styles.modalInputsContainer}>
                        <input
                            className={styles.modalInput}
                            value={data.fullname}
                            placeholder={t('Full Name')}
                            type='text'
                            onChange={e => setData({ ...data, fullname: e.target.value })}
                        />
                        {/* <div className={styles.typesContainer}>
                            <div onClick={()=>setActive(1)} className={active === 1? styles.activeImageContainer: styles.imageContainer}>
                                <img src={cardImage} alt="Logo" className={styles.imageCard} />
                            </div>
                            <div  onClick={()=>setActive(2)} className={active === 2? styles.activeImageContainer: styles.imageContainer}>
                                <img src={tagImage} alt="Logo" className={styles.imageTag} />
                            </div>
                            <div onClick={()=>setActive(3)}  className={active === 3? styles.activeImageContainer: styles.imageContainer}>
                                <img src={appleImage} alt="Logo" className={styles.imageApple} />
                            </div>
                            <div onClick={()=>setActive(4)}  className={active === 4? styles.activeImageContainer: styles.imageContainer}>
                                <img src={androidImage} alt="Logo" className={styles.imageAndroid} />
                            </div>
                        </div> */}
                        <input
                            className={styles.modalInput}
                            value={data.card}
                            placeholder={t('Card Id')}
                            type='text'
                            onChange={e => setData({ ...data, card: e.target.value })}
                        />

                        <div className={styles.switchContainer}>
                            <p className={styles.switchLabel}>VIP</p>
                            <Switch value={data.isGlobalUsed} onClick={swicthValue => setData({ ...data, isGlobalUsed: swicthValue })} />
                        </div>
                        {console.log(data)}
                        <button
                            className={styles.button}
                            value="text" >
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            {t('Add')}
                        </button>
                        <Button type={"cancel"}
                            placeholder={t('Cancel')}
                            action={() => setModal(false)}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
export default ModalComponentAddCard;