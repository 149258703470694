import styles from './Index.module.css';
import rectangle from "../../assets/rectangle.png";
function Loading({size}) {
    if (size === "small") {
        return (
        <div className={styles.fullScreenSmall}>
            <div className={styles.rectanglesContainer}>
                <img src={rectangle} alt="Logo" className={styles.rectangle3} />
                <img src={rectangle} alt="Logo" className={styles.rectangle2} />
                <img src={rectangle} alt="Logo" className={styles.rectangle1} />
            </div>
        </div>
        )
    } else {
        return (
            <div className={styles.fullScreen}>
                <div className={styles.rectanglesContainer}>
                    <img src={rectangle} alt="Logo" className={styles.rectangle3} />
                    <img src={rectangle} alt="Logo" className={styles.rectangle2} />
                    <img src={rectangle} alt="Logo" className={styles.rectangle1} />
                </div>
            </div>
        );
    }

}
export default Loading;
