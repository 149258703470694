import axios from 'axios';
import url from "../constants/url";

export default function editLiftHandler(e, modalDataOld, dataElevator, setModal, reloadList, setResponseStatus,setSuccess,setMessage,setHidden) {
    e.preventDefault();

    axios({
        method: 'put',
        url: url + "/upd_lift",
        headers: {
            'authKey': localStorage.getItem("auth_key"),
            'lift': parseInt(modalDataOld.lift)
        },
        data: {
            blok: parseInt(dataElevator.blok),
            floor: parseInt(dataElevator.floor),
            liftName: dataElevator.liftName,
            isCamera: dataElevator.isCamera ? 1 : 0,
            camPassword: dataElevator.isCamera ? dataElevator.camPassword : "",
            camIp: dataElevator.isCamera ? dataElevator.camIp : "",
            mac: dataElevator.mac,
            garage: dataElevator.garage ? 1 : 0
        }
    })
        .then(function (response) {
            console.log("success")
            console.log(response.data);
            setModal(false);
            reloadList();
            setResponseStatus(true)
        }).catch(function (error) {
            console.log(error)
            console.log("fail")
            setSuccess(false)
            setMessage(error.message)
            setHidden(false)
        });
}