import React, {  useEffect } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';



function MonitoringLineChart() {
    const data = [
        {
            name: 'Page A',
            uv: 1000,
        },
        {
            name: 'Page B',
            uv: 1600
        },
        {
            name: 'Page C',
            uv: 1200,
        },
        {
            name: 'Page D',
            uv: 3780,
        },
        {
            name: 'Page E',
            uv: 1890,
        },
        {
            name: 'Page F',
            uv: 2890,
        },
        {
            name: 'Page G',
            uv: 2290,
        },
    ];

    useEffect(() => {
        
    }, []
    );


    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                width={500}
                height={400}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 30,
                    bottom: 10,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#9f0101" />
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default MonitoringLineChart;