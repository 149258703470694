import styles from './Index.module.css';
import React from "react";
import PageHeader from '../PageHeader/PageHeader';
import SecondWindow from '../SecondWindow/SecondWindow';
import MonitoringPieChart from '../MonitoringPieChart/MonitoringPieChart';
import MonitoringLineChart from '../MonitoringLineChart/MonitoringLineChart';
import MonitoringStackedLineChart from '../MonitoringStackedLineChart/MonitoringStackedLineChart';
import MainCoverComponent from "../MainCoverComponent/MainCoverComponent";
import { useTranslation } from 'react-i18next';
function Monitoring() {

    const { t } = useTranslation();
    return (
        <div className={styles.main}>

            <MainCoverComponent>
                <PageHeader>{t('Monitoring')} </PageHeader>
                <SecondWindow>
                    <div className={styles.windowsContainer}>
                        <div className={styles.largeWindow}>
                            <p className={styles.label}>{t('Payment Monitoring')}</p>
                            <MonitoringStackedLineChart />

                        </div>
                        <div className={styles.window}>
                            <p className={styles.label}>{t('Charts')}</p>
                            <MonitoringLineChart />
                        </div>
                        <div className={styles.window}>
                            <p className={styles.label}>{t('Charts')}</p>
                            <MonitoringPieChart />
                        </div>
                    </div>

                </SecondWindow>
            </MainCoverComponent>
        </div>
    );
}

export default Monitoring;