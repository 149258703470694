import React from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';




function MonitoringStackedLineChart() {
    const data = [
        {
            date: '01.2024',
            amount: 4000,
            activeUsers: 2400,
            userCount: 2400,
        },
        {
            date: '02.2024',
            amount: 3000,
            activeUsers: 1398,
            userCount: 2210,
        },
        {
            date: '03.2024',
            amount: 2000,
            activeUsers: 9800,
            userCount: 2290,
        },
        {
            date: '04.2024',
            amount: 2780,
            activeUsers: 3908,
            userCount: 2000,
        },
        {
            date: '05.2024',
            amount: 1890,
            activeUsers: 4800,
            userCount: 2181,
        },
        {
            date: '06.2024',
            amount: 2390,
            activeUsers: 3800,
            userCount: 2500,
        },
        {
            date: '07.2024',
            amount: 3490,
            activeUsers: 4300,
            userCount: 2100,
        },
    ];

    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                width={500}
                height={400}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 30,
                    bottom: 10,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="amount" stackId="1" stroke="#6f008d" fill="#6f008d" />
                <Area type="monotone" dataKey="activeUsers" stackId="1" stroke="#006924" fill="#006924" />
                <Area type="monotone" dataKey="userCount" stackId="1" stroke="#d59b00" fill="#d59b00" />
            </AreaChart>
        </ResponsiveContainer>

    );
}

export default MonitoringStackedLineChart;