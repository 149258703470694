import styles from './Index.module.css';
import React from "react";

function PageHeader(props) {
    return (
        <div className={styles.headerContainer}>
            <p className={styles.header}>{props.children}</p>
        </div>
    );
}
export default PageHeader;