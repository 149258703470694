import styles from './Index.module.css';
import React from "react";
import PageHeader from '../PageHeader/PageHeader';
import SecondWindow from '../SecondWindow/SecondWindow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFolder } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import MainCoverComponent from "../MainCoverComponent/MainCoverComponent";

import { useTranslation } from 'react-i18next';

function Recycle() {
    const { t } = useTranslation();
    return (
        <div className={styles.main}>


            <MainCoverComponent>
                <PageHeader> {t('Recycle Bin')}</PageHeader>

                <SecondWindow>
                    <div className={styles.foldersContainer}>
                        <Link className={styles.cardContainer} to="/Recycle_Admins">
                            <FontAwesomeIcon icon={faFolder} className={styles.icon} />
                            <p className={styles.title}>{t('Admins')}</p>
                        </Link>
                        <Link className={styles.cardContainer} to="/Recycle_Companies">
                            <FontAwesomeIcon icon={faFolder} className={styles.icon} />
                            <p className={styles.title}>{t('Companies')}</p>
                        </Link>
                        <Link className={styles.cardContainer} to="/Recycle_Buildings">
                            <FontAwesomeIcon icon={faFolder} className={styles.icon} />
                            <p className={styles.title}>{t('Buildings')}</p>
                        </Link>
                        <Link className={styles.cardContainer} to="/Recycle_Bloks">
                            <FontAwesomeIcon icon={faFolder} className={styles.icon} />
                            <p className={styles.title}>{t('Entrances')}</p>
                        </Link>
                        <Link className={styles.cardContainer} to="/Recycle_Lifts">
                            <FontAwesomeIcon icon={faFolder} className={styles.icon} />
                            <p className={styles.title}>{t('Elevators')}</p>
                        </Link>
                        <Link className={styles.cardContainer} to="/Recycle_Users">
                            <FontAwesomeIcon icon={faFolder} className={styles.icon} />
                            <p className={styles.title}>{t('Users')}</p>
                        </Link>
                    </div>
                </SecondWindow>
            </MainCoverComponent>

        </div>
    );
}

export default Recycle;