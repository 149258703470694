import styles from './Index.module.css';
import React, { useState , useEffect} from "react";
import { Routes, Route } from "react-router-dom";
import TopNav from '../../components/TopNav/TopNav';
import LeftMenu from '../../components/LeftMenu/LeftMenu';
import ListAdmins from '../../components/ListAdmins/ListAdmins';
import EditCompany from '../../components/EditCompanies/EditCompany';
import ListCompanies from "../../components/ListCompanies/ListCompanies";
import ListBuildings from '../../components/ListBuildings/ListBuildings';
import ListUsers from '../../components/ListUsers/ListUsers';
import ListBlocks from '../../components/ListBlocks/ListBlocks';
import ListElevators from '../../components/ListElevators/ListElevators';
import EditBuilding from '../../components/EditBuilding/EditBuilding';
import EditBlock from '../../components/EditBlock/EditBlock';
import EditElevators from '../../components/EditElevators/EditElevators';
import MapView from '../../components/MapView/MapView';
import Monitoring from "../../components/Monitoring/Monitoring";
import Cards from '../../components/Cards/Cards';
import Recycle from '../../components/Recycle/Recycle';
import RecycleAdmins from '../../components/RecycleAdmins/RecycleAdmins';
import RecycleCompanies from '../../components/RecycleCompanies/RecycleCompanies';
import RecycleBuildings from '../../components/RecycleBuildings/RecycleBuildings';
import RecycleBloks from '../../components/RecycleBloks/RecycleBloks';
import RecycleLifts from '../../components/RecycleLifts/RecycleLifts';
import RecycleUsers from '../../components/RecycleUsers/RecycleUsers';
import Support from '../../components/Support/Support';
function superAdminRoutes() {

    return (
        <Routes>
            <Route path="/listAdmins" element={<ListAdmins />} />
            <Route path="/companySettings" element={<EditCompany />} />
            <Route path="/Companies" element={<ListCompanies />} />
            <Route path="/ListBuildings" element={<ListBuildings />} />
            <Route path="/EditBuildings" element={<EditBuilding />} />
            <Route path="/ListBlocks" element={<ListBlocks />} />
            <Route path="/EditBlocks" element={<EditBlock />} />
            <Route path="/ListElevators" element={<ListElevators />} />
            <Route path="/EditElevators" element={<EditElevators />} />
            <Route path="/MapView" element={<MapView />} />
            <Route path="/Cards" element={<Cards />} />
            <Route path="/Monitoring" element={<Monitoring />} />
            <Route path="/Recycle" element={<Recycle />} />
            <Route path="/Recycle_Admins" element={<RecycleAdmins />} />
            <Route path="/Recycle_Companies" element={<RecycleCompanies />} />
            <Route path="/Recycle_Buildings" element={<RecycleBuildings />} />
            <Route path="/Recycle_Bloks" element={<RecycleBloks />} />
            <Route path="/Recycle_Lifts" element={<RecycleLifts />} />
            <Route path="/Recycle_Users" element={<RecycleUsers />} />
            <Route path="/Support" element={<Support />} />
            <Route index path="/" element={<ListUsers />} />
        </Routes>
    );
}
function adminRoutes() {
    return (
        <Routes>
            <Route path="/Companies" element={<ListCompanies />} />
            <Route path="/companySettings" element={<EditCompany />} />
            <Route path="/ListBuildings" element={<ListBuildings />} />
            <Route path="/EditBuildings" element={<EditBuilding />} />
            <Route path="/ListBlocks" element={<ListBlocks />} />
            <Route path="/EditBlocks" element={<EditBlock />} />
            <Route path="/ListElevators" element={<ListElevators />} />
            <Route path="/EditElevators" element={<EditElevators />} />
            <Route path="/MapView" element={<MapView />} />
            <Route path="/Cards" element={<Cards />} />
            <Route path="/Monitoring" element={<Monitoring />} />
            <Route path="/Support" element={<Support />} />
            <Route index path="/" element={<ListUsers />} />
            
        </Routes>
    );
}
function companyRoutes() {
    return (
        <Routes>
            <Route index path="/ListBuildings" element={<ListBuildings />} />
            <Route path="/ListBlocks" element={<ListBlocks />} />
            <Route path="/ListElevators" element={<ListElevators />} />
            <Route path="/MapView" element={<MapView />} />
            <Route path="/Cards" element={<Cards />} />
            <Route path="/Monitoring" element={<Monitoring />} />
            <Route path="/Support" element={<Support />} />
            <Route index path="/" element={<ListUsers />} />
        </Routes>
    );
}
function buildingRoutes() {
    return (
        <Routes>
            <Route path="/ListBlocks" element={<ListBlocks />} />
            <Route path="/ListElevators" element={<ListElevators />} />
            <Route path="/MapView" element={<MapView />} />
            <Route path="/Cards" element={<Cards />} />
            <Route path="/Monitoring" element={<Monitoring />} />
            <Route path="/Support" element={<Support />} />
            <Route index path="/" element={<ListUsers />} />
        </Routes>
    );
}


function MasterAdmin({logOut}) {

    // const [loading, setLoading] = useState(true);

    const [activeMenu, setActiveMenu] = useState(false);

    useEffect(() => {
        if(window.innerWidth > 700 ){
            setActiveMenu(false)
        }
    }, []
    );
    
    return (
        <div className={styles.main}>
            {/* { loading?<Loading /> : null} */}
            <TopNav activeMenu={activeMenu} setActiveMenu={setActiveMenu} logOut={logOut}/>
            <LeftMenu activeMenu={activeMenu} setActiveMenu={setActiveMenu} />

            {localStorage.getItem("type") === "3" ? buildingRoutes()
                :localStorage.getItem("type") === "0" ? superAdminRoutes()
                : localStorage.getItem("type") === "1" ? adminRoutes()
                    : localStorage.getItem("type") === "2" ? companyRoutes()
                        : ""}


        </div>
    );
}

export default MasterAdmin;