import axios from 'axios';
import url from "../constants/url";

export default function editBlockHandler(e,data, modalDataOld, setModal, reloadList,setSuccess,setResponseStatus,setMessage,setHidden) {
    e.preventDefault();
    axios({
        method: 'put',
        url: url + "/upd_blok",
        headers: {
            'authKey': localStorage.getItem("auth_key"),
            'blok': parseInt(modalDataOld.blok)
        },
        data: {
            name: data.name,
            building: parseInt(data.building),
            floor: parseInt(data.floor),
            mac: data.mac
        }
    })
        .then(function (response) {
            console.log("success")
            console.log(response.data);
            setModal(false);
            reloadList();
            setResponseStatus(true)
        }).catch(function (error) {
            console.log(error)
            console.log("fail")
            setSuccess(false)
            setMessage(error.message)
            setHidden(false)
        });
}