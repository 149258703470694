import React from "react";
import styles from './Index.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
const ModalComponentAddBlock = ({ setModal, addBlockHandler, data, setData, companies, companySelectChangeHandler,activeCompany,buildings }) => {
    const { t } = useTranslation();
    return (
        <div className={styles.modalWindow}>
            <div className={styles.modalContainer}>
                <p className={styles.modalTitle}>Add Block</p>
                <FontAwesomeIcon onClick={() => setModal(false)} icon={faXmark} className={styles.icon} />
                <form onSubmit={(e) => addBlockHandler(e)} className={styles.modalInputsContainer}>
                    <div className={styles.selectWrapper}>
                        <select onChange={e => companySelectChangeHandler(e)} className={styles.select}>
                            <option value="noFilter">{t('Company')}</option>
                            {companies.map(item => <option key={item.voen} value={item.voen}>{item.companyName}</option>)}
                        </select>
                    </div>
                    <div className={styles.selectWrapper}>
                        <select disabled={activeCompany === "noFilter"} onChange={e => setData({ ...data, building: e.target.value })} className={styles.select}>
                            {buildings.map(item => <option value={item.building}>{item.name}</option>)}
                        </select>
                    </div>
                    <input
                        className={styles.modalInput}
                        value={data.mac}
                        placeholder={t('MAC Address')}
                        type='text'
                        onChange={e => setData({ ...data, mac: e.target.value })}
                    />

                    <input
                        className={styles.modalInput}
                        value={data.name}
                        placeholder={t('Entrance Name')}
                        type='text'
                        onChange={e => setData({ ...data, name: e.target.value })}
                    />
                    <input
                        className={styles.modalInput}
                        value={data.floor}
                        placeholder={t('Floor')}
                        type='text'
                        onChange={e => setData({ ...data, floor: e.target.value })}
                    />
                    <button
                        className={styles.button}
                        value="text" >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        {t('Add Entrance')}
                    </button>
                    <Button type={"cancel"}
                        placeholder={t('Cancel')}
                        action={() => setModal(false)}
                    />
                </form>
            </div>
        </div>
    );
};
export default ModalComponentAddBlock;