import styles from './Index.module.css';
import React, { useState, useEffect } from "react";
import ModalWindow from '../ModalWindow/ModalWindow';
import PageHeader from '../PageHeader/PageHeader';
import SecondWindow from '../SecondWindow/SecondWindow';
import axios from 'axios';
import url from "../../constants/url";
import ResponseMessage from "../ResponseMessage/ResponseMessage";
import MainCoverComponent from "../MainCoverComponent/MainCoverComponent";
import AddButton from '../AddButton/AddButton';
import SearchInput from "../SearchInput/SearchInput";
import { useTranslation } from 'react-i18next';
function ListAdmins() {
    const [search, setSearch] = useState('');
    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalData, setModalData] = useState(null);
    const [list, setList] = useState([]);

    const [responseStatus, setResponseStatus] = useState(false);

    const { t } = useTranslation();


    function reloadList() {
        setTimeout(() => setResponseStatus(false), 2000)
        axios({
            method: 'get',
            url: url + "/getall_employees",
            headers: {
                'authKey': localStorage.getItem("auth_key")
            }
        })
            .then(function (response) {
                console.log("success")
                setList([...response.data]);
            }).catch(function (error) {
                console.log("fail")
            });
    }
    useEffect(() => {
        reloadList();
    }, []
    );



    return (
        <div className={styles.main}>

            {modal ? <ModalWindow
                modalType={modalType}
                modalData={{ ...modalData }}
                setModal={setModal}
                reloadList={() => reloadList()}
                setResponseStatus={() => setResponseStatus(true)}
            /> : null}


            <MainCoverComponent>
                <PageHeader> {t('Admins List')}</PageHeader>

                <SecondWindow>

                    <div className={styles.firstRaw}>
                        <AddButton
                            type={"addAdmin"}
                            setModalType={setModalType}
                            setModalData={setModalData}
                            setModal={setModal}
                        />

                        <SearchInput
                            value={search}
                            setValue={setSearch}
                        />

                    </div>


                    <div className={styles.dataListTitlesContainer}>
                        <div className={styles.singleTitleContainer}>
                            <p className={styles.title}>{t('Id')}</p>
                        </div>
                        <div className={styles.singleTitleContainer}>
                            <p className={styles.title}>{t('Surname')}</p>
                        </div>
                        <div className={styles.singleTitleContainer}>
                            <p className={styles.title}>{t('Name')}</p>
                        </div>
                        <div className={styles.singleTitleContainer}>
                            <p className={styles.title}>{t('Father')}</p>
                        </div>
                        <div className={styles.singleTitleContainer}>
                            <p className={styles.title}>{t('Number')}</p>
                        </div>
                        <div className={styles.singleTitleContainer}>
                            <p className={styles.title}>{t('Password')}</p>
                        </div>
                    </div>



                    {
                        list.map(item =>
                            <div
                                key={item.employee}
                                onClick={() => {
                                    setModalType("editAdmin")
                                    setModalData({ ...item })
                                    setModal(true)
                                }}
                                className={styles.tableRow}
                            >
                                <div className={styles.singleTitleContainer}>
                                    <p className={styles.title}>{item.employee}</p>
                                </div>
                                <div className={styles.singleTitleContainer}>
                                    <p className={styles.title}>{item.surname}</p>
                                </div>
                                <div className={styles.singleTitleContainer}>
                                    <p className={styles.title}>{item.name}</p>
                                </div>
                                <div className={styles.singleTitleContainer}>
                                    <p className={styles.title}>{item.father_name}</p>
                                </div>
                                <div className={styles.singleTitleContainer}>
                                    <p className={styles.title}>{item.phone_number}</p>
                                </div>
                                <div className={styles.singleTitleContainer}>
                                    <p className={styles.title}>{item.password}</p>
                                </div>
                            </div>
                        )
                    }
                </SecondWindow>
            </MainCoverComponent>
            <ResponseMessage hidden={!responseStatus} success={"success"} message={modalType === "addAdmin" ? "Admin Added Successfully" : "Saved Successfully"} />
        </div>
    );
}

export default ListAdmins;