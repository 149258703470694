import axios from 'axios';
import url from "../constants/url";

export default function addLiftHandler(e, data, setModal, reloadList,setResponseStatus,setSuccess,setMessage,setHidden) {
    e.preventDefault();
    axios({
        method: 'post',
        url: url + "/add_lift",
        headers: {
            'authKey': localStorage.getItem("auth_key")
        },
        data: {
            blok: parseInt(data.blok),
            floor: parseInt(data.floor),
            liftName: data.liftName,
            isCamera: data.isCamera ? 1 : 0,
            camPassword: data.camPassword,
            camIp: data.camIp,
            mac: data.mac,
            garage: data.garage ? 1 : 0
        }
    })
        .then(function (response) {
            console.log("success");
            console.log(response.data);
            setModal(false);
            reloadList();
            setResponseStatus(true)
        }).catch(function (error) {
            console.log(error)
            console.log("fail")
            setSuccess(false)
            setMessage(error.message)
            setHidden(false)
        });
}