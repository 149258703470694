import styles from './Index.module.css';
import React from "react";
import { useTranslation } from 'react-i18next';
function SearchInput({ value, setValue }) {
    const { t } = useTranslation();
    return (
        <div className={styles.inputContainer}>
            <input
                className={styles.input}
                value={value}
                placeholder={t('Search')}
                type='text'
                onChange={e => setValue(e.target.value)}
            />
        </div>
    );
}

export default SearchInput;