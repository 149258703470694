import styles from './Index.module.css';
import React from "react";
import logo from '../../assets/logoLiftBig.png';
import avatar from "../../assets/avatarmain.jpg";
import building from "../../assets/building.jpg";
import company from "../../assets/company.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
function TopNav({ activeMenu, setActiveMenu, logOut }) {
    // const [userName, setUserName] = useState('');

    const { t } = useTranslation();
    return (
        <div className={styles.main}>
            <div className={styles.leftContainer}>
                <div className={styles.leftInnerContainer}>
                    <div onClick={() => setActiveMenu(!activeMenu)} className={styles.hamburgerContainer}>
                        <div className={activeMenu ? styles.hamburgerLine1Active : styles.hamburgerLine1}></div>
                        <div className={activeMenu ? styles.hamburgerLine2Active : styles.hamburgerLine2}></div>
                        <div className={activeMenu ? styles.hamburgerLine3Active : styles.hamburgerLine3}></div>
                    </div>
                </div>

            </div>
            <LanguageSwitcher />
            <div className={styles.centerContainer}>
                <img src={logo} alt="Logo" className={styles.logo} />
            </div>

            <div className={styles.rightContainer}>
                <div className={styles.textContainer}>
                    <p className={styles.userText}>{
                        localStorage.getItem("type") === "1" ? localStorage.getItem("name") + " " + localStorage.getItem("surname") :
                            localStorage.getItem("type") === "2" ? localStorage.getItem("companyName") :
                                localStorage.getItem("type") === "3" ? localStorage.getItem("name") :
                                    localStorage.getItem("type") === "0" ? localStorage.getItem("name") : " "}</p>
                    <p className={styles.userTextSecond}>
                        {localStorage.getItem("type") === "0" ? t('Super Admin')
                            : localStorage.getItem("type") === "1" ? t('Admin')
                                : localStorage.getItem("type") === "2" ? t('Company')
                                    : localStorage.getItem("type") === "3" ? t('Building')
                                        : "User"}
                    </p>
                </div>

                <div className={styles.avatarContainer}>
                    {
                        localStorage.getItem("type") === "1" ? <img src={avatar} alt="Logo" className={styles.avatar} /> :
                            localStorage.getItem("type") === "2" ? <img src={company} alt="Logo" className={styles.avatar} /> :
                                localStorage.getItem("type") === "3" ? <img src={building} alt="Logo" className={styles.avatar} /> :
                                    <img src={avatar} alt="Logo" className={styles.avatar} />
                    }


                </div>

                <FontAwesomeIcon onClick={() => logOut()} icon={faArrowRightFromBracket} className={styles.icon} />
            </div>

        </div>
    );
}

export default TopNav; 