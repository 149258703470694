import axios from 'axios';
import url from "../constants/url";

export default function addAdminHandler(event, data, setModal, reloadList,setResponseStatus,setSuccess,setMessage,setHidden) {
    event.preventDefault();
    axios({
        method: 'post',
        url: url + "/add_emp",
        headers: {
            'authKey': localStorage.getItem("auth_key")
        },
        data: {
            name: data.name,
            surname: data.surname,
            father_name: data.father_name,
            password: data.password,
            phone_number: data.phone_number,
            employee: data.employee,
            employee_type: 1
        }
    })
        .then(function (response) {
            console.log("success");
            console.log(response.data);
            setModal(false);
            reloadList();
            setResponseStatus(true)

        }).catch(function (error) {
            console.log(error)
            console.log("fail")
            // setLoading(false)
            setSuccess(false)
            setMessage(error.message)
            setHidden(false)
            setTimeout(()=>setHidden(true), 2000)
        });
}